<template>
  <nav class="sidebar">
    <h2 class="sidebar-heading">Our Solutions</h2>
    <ul class="menu-list">
      <li v-for="(item, index) in menuItems" :key="index" class="menu-item">
        <router-link
          :to="item.path"
          class="menu-link"
          :class="{ active: currentPath === item.path }"
          @click="scrollToTop" 
        >
          <span class="menu-text">{{ item.title }}</span>
          <v-icon
            class="chevron-icon"
            v-if="currentPath === item.path"
            icon="mdi-chevron-right"
          />
        </router-link>
        <span class="underline"></span>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'ServiceSidebar',
  data() {
    return {
      menuItems: [
        { title: 'Design My App', path: '/design-my-app' },
        { title: 'Build My App', path: '/build-my-app' },
        { title: 'Review My App', path: '/review-my-app' },
        { title: 'Roadmap My App', path: '/roadmap-my-app' },
        { title: 'Staff Augmentation', path: '/staff-augmentation' },
        {
          title: 'Identity Management for My App',
          path: '/implement-identity-management',
        },
      ],
    };
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
.sidebar {
  width: 380px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;
  font-family: Arial, sans-serif;
  height: 540px;
  text-align: left;
}

.sidebar-heading {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 16px;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-text {
  font-size: 1.2rem;
  padding: 0.5rem;
}

.menu-item {
  position: relative;
}

/* Modify the menu-link styles */
.menu-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  font-size: 0.9375rem;
  color: #4b5563;
  text-decoration: none;
  transition: color 0.3s ease;
  position: relative;
}

/* Dotted underline by default */
.menu-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #d1d5db 50%, transparent 50%);
  background-size: 8px 2px;
}

/* Blue animated overlay */
.menu-link::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #00aeff;
  transition: width 0.5s ease;
  visibility: hidden; /* Hide by default */
}

/* Show and animate blue line on hover */
.menu-link:hover::before {
  visibility: visible;
  width: 100%;
}

/* Keep blue line visible and full width for active state */
.menu-link.active::before {
  visibility: visible;
  width: 100%;
}

/* Hide dotted line when link is active */
.menu-link.active::after {
  visibility: hidden;
}

.menu-link:hover::after {
  visibility: hidden;
}

/* Chevron icon alignment */
.chevron-icon {
  font-size: 2rem;
  color: #00aeff;
}

/* Responsive styles for screen width below 1280px */
@media (max-width: 1280px) {
  .sidebar {
    width: 300px; /* Reduce width */
    padding: 16px; /* Adjust padding */
    height: 450px;
  }

  .sidebar-heading {
    font-size: 1.25rem; /* Decrease font size */
  }

  .menu-text {
    font-size: 1rem; /* Decrease font size */
  }

  .menu-link {
    padding: 10px 0; /* Adjust padding */
    font-size: 0.875rem; /* Adjust text size */
  }

  .chevron-icon {
    font-size: 1.5rem; /* Adjust icon size */
  }
}
</style>
