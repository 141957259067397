<template>
  <div class="menu--mobile">
    <v-dialog
      class="mobile__menu"
      v-model="displayMobileMenu"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ props }">
        <div class="menu__container">
          <v-icon class="mobile-menu__icon" v-bind="props">mdi-menu</v-icon>
        </div>
      </template>
      <v-card class="mobile-menu__card">
        <v-card-title>
          <v-row class="mobile-menu__card-title-row">
            <v-col cols="6">
              <h3 class="mobile-menu__title dms400">DIGU</h3>
            </v-col>
            <v-col cols="3" class="close-button-container">
              <v-icon @click="displayMobileMenu = false" class="close-btn"
                >mdi-close</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-list class="mobile-menu__list">
            <!-- START: Home Menu -->
            <v-list-item density="compact" class="mobile-menu__list-item">
              <v-list-item-text>
                <a class="m400 mobile-menu__link link--primary" href="/">
                  Home
                </a>
              </v-list-item-text>
            </v-list-item>
            <!-- END: Home Menu -->
            <!-- START: About Menu -->
            <v-list-item density="compact" class="mobile-menu__list-item">
              <v-list-item-text>
                <a class="m400 mobile-menu__link link--primary" href="/about">
                  About
                </a>
              </v-list-item-text>
            </v-list-item>
            <!-- END About Menu -->
            <!-- START: DIGU Cares Menu -->
            <v-list-item density="compact" class="mobile-menu__list-item">
              <v-list-item-text>
                <a class="m400 mobile-menu__link link--primary" href="/care"
                  >DIGU Cares</a
                >
              </v-list-item-text>
            </v-list-item>
            <!-- END: Smartworks Cares Menu -->
            <!-- START: For Business Menu -->
            <v-list-item density="compact" class="mobile-menu__list-item">
              <v-list-item-text
                class="mobile-menu__list-item-text menu-text--secondary"
              >
                <v-expansion-panels style="padding: 0px" class="ep">
                  <v-expansion-panel
                    elevation="0"
                    class="mb-3"
                    style="background-color: transparent; padding-left: 0px"
                  >
                    <v-expansion-panel-title expand-icon="mdi">
                      <p class="m400 mobile-menu__link link--primary">
                        For Businesses
                      </p>
                      <template v-slot:actions="{ expanded }">
                        <v-icon
                          color="white"
                          :icon="
                            expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'
                          "
                        ></v-icon>
                      </template>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text class="faq__answer">
                      <v-list class="mobile-menu__list list--secondary">
                        <v-list-item
                          density="compact"
                          class="mobile-menu__list-item"
                        >
                          <v-list-item-text
                            class="mobile-menu__list-item-text menu-text--secondary"
                          >
                            <a
                              class="m400 mobile-menu__link link--secondary"
                              href="/for-hiring-companies"
                              >DIGU For Businesses</a
                            ></v-list-item-text
                          >
                        </v-list-item>
                        <v-list-item
                          density="compact"
                          class="mobile-menu__list-item"
                        >
                          <v-list-item-text
                            class="mobile-menu__list-item-text menu-text--secondary"
                          >
                            <v-expansion-panels style="padding: 0px" class="ep">
                              <v-expansion-panel
                                elevation="0"
                                class="mb-3"
                                style="
                                  background-color: transparent;
                                  padding-left: 0px;
                                "
                              >
                                <v-expansion-panel-title expand-icon="mdi">
                                  <p
                                    class="m400 mobile-menu__link link--secondary"
                                  >
                                    Our Services
                                  </p>
                                  <template v-slot:actions="{ expanded }">
                                    <v-icon
                                      color="white"
                                      :icon="
                                        expanded
                                          ? 'mdi-chevron-up'
                                          : 'mdi-chevron-down'
                                      "
                                    ></v-icon>
                                  </template>
                                </v-expansion-panel-title>
                                <v-expansion-panel-text class="faq__answer">
                                  <v-list
                                    class="mobile-menu__list list--secondary"
                                  >
                                    <v-list-item
                                      density="compact"
                                      class="mobile-menu__list-item"
                                    >
                                      <v-list-item-text
                                        class="mobile-menu__list-item-text menu-text--secondary"
                                      >
                                        <a
                                          class="m400 mobile-menu__link link--secondary"
                                          href="/design-my-app"
                                          >Design My App</a
                                        ></v-list-item-text
                                      >
                                    </v-list-item>
                                    <v-list-item
                                      density="compact"
                                      class="mobile-menu__list-item"
                                    >
                                      <v-list-item-text
                                        class="mobile-menu__list-item-text menu-text--secondary"
                                      >
                                        <a
                                          class="m400 mobile-menu__link link--secondary"
                                          href="/build-my-app"
                                          >Build My App</a
                                        ></v-list-item-text
                                      >
                                    </v-list-item>
                                    <v-list-item
                                      density="compact"
                                      class="mobile-menu__list-item"
                                    >
                                      <v-list-item-text
                                        class="mobile-menu__list-item-text menu-text--secondary"
                                      >
                                        <a
                                          class="m400 mobile-menu__link link--secondary"
                                          href="/review-my-app"
                                          >Review My App</a
                                        ></v-list-item-text
                                      >
                                    </v-list-item>
                                    <v-list-item
                                      density="compact"
                                      class="mobile-menu__list-item"
                                    >
                                      <v-list-item-text
                                        class="mobile-menu__list-item-text menu-text--secondary"
                                      >
                                        <a
                                          class="m400 mobile-menu__link link--secondary"
                                          href="/roadmap-my-app"
                                          >Roadmap My App</a
                                        ></v-list-item-text
                                      >
                                    </v-list-item>
                                    <v-list-item
                                      density="compact"
                                      class="mobile-menu__list-item"
                                    >
                                      <v-list-item-text
                                        class="mobile-menu__list-item-text menu-text--secondary"
                                      >
                                        <a
                                          class="m400 mobile-menu__link link--secondary"
                                          href="/staff-augmentation"
                                          >Staff Augmentation</a
                                        ></v-list-item-text
                                      >
                                    </v-list-item>
                                    <v-list-item
                                      density="compact"
                                      class="mobile-menu__list-item"
                                    >
                                      <v-list-item-text
                                        class="mobile-menu__list-item-text menu-text--secondary"
                                      >
                                        <a
                                          class="m400 mobile-menu__link link--secondary"
                                          href="/implement-identity-management"
                                          >Identity Management for My App</a
                                        ></v-list-item-text
                                      >
                                    </v-list-item>
                                  </v-list>
                                </v-expansion-panel-text>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-list-item-text>
                        </v-list-item>
                      </v-list>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-list-item-text>
            </v-list-item>
            <!-- END: For Business Menu -->
            <!-- START: For Job Seekers Menu -->
            <v-list-item density="compact" class="mobile-menu__list-item">
              <v-list-item-text
                class="mobile-menu__list-item-text menu-text--secondary"
              >
                <v-expansion-panels style="padding: 0px" class="ep">
                  <v-expansion-panel
                    elevation="0"
                    class="mb-3"
                    style="background-color: transparent; padding-left: 0px"
                  >
                    <v-expansion-panel-title expand-icon="mdi">
                      <p class="m400 mobile-menu__link link--primary">
                        For Job Seekers
                      </p>
                      <template v-slot:actions="{ expanded }">
                        <v-icon
                          color="white"
                          :icon="
                            expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'
                          "
                        ></v-icon>
                      </template>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text class="faq__answer">
                      <v-list class="mobile-menu__list list--secondary">
                        <v-list-item
                          density="compact"
                          class="mobile-menu__list-item"
                        >
                          <v-list-item-text
                            class="mobile-menu__list-item-text menu-text--secondary"
                          >
                            <a
                              class="m400 mobile-menu__link link--secondary"
                              href="/why-digu-for-career-opportunities"
                              >Why DIGU For Career Opportunities</a
                            ></v-list-item-text
                          >
                        </v-list-item>
                        <v-list-item
                          density="compact"
                          class="mobile-menu__list-item"
                        >
                          <v-list-item-text
                            class="mobile-menu__list-item-text menu-text--secondary"
                          >
                            <a
                              class="m400 mobile-menu__link link--secondary"
                              href="/job-placement-program"
                              >Learn about our Job Placement Program</a
                            ></v-list-item-text
                          >
                        </v-list-item>
                        <v-list-item
                          density="compact"
                          class="mobile-menu__list-item"
                        >
                          <v-list-item-text
                            class="mobile-menu__list-item-text menu-text--secondary"
                          >
                            <a
                              class="m400 mobile-menu__link link--secondary"
                              href="/talents-on-demand"
                              >Join Our Talent Pool</a
                            ></v-list-item-text
                          >
                        </v-list-item>
                      </v-list>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-list-item-text>
            </v-list-item>
            <!-- END: For Job Seekers Menu -->
            <!-- START: Contact Menu -->
            <v-list-item density="compact" class="mobile-menu__list-item">
              <v-list-item-text>
                <a class="m400 mobile-menu__link link--primary" href="/contact"
                  >Contact</a
                >
              </v-list-item-text>
            </v-list-item>
            <!-- END: Contact Menu -->
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'MobileMenu',
  data: () => ({
    displayMobileMenu: false,
  }),
};
</script>

<style scoped>
@media only screen and (min-width: 1282px) {
  .menu--mobile {
    display: none;
  }
}

.mobile-menu__icon {
  color: var(--primary-color);
  font-size: 2rem;
}

.menu__container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mobile__menu {
  background-color: #292929ee;
  backdrop-filter: blur(10px);
}

.mobile-menu__card {
  background: transparent;
  padding: 2rem;
  padding-bottom: 10rem;
}

.mobile-menu__card-title-row {
  justify-content: space-between;
}

.mobile-menu__title {
  font-size: 1.2rem;
  letter-spacing: 0px;
  color: var(--secondary-color-lightest);
}

@media only screen and (min-width: 394px) {
  .mobile-menu__title {
    font-size: 1.5rem;
  }
}

.close-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.close-btn {
  color: var(--secondary-color-lightest);
  font-size: 1.5rem !important;
}

.mobile-menu__list {
  background: transparent;
}

.mobile-menu__link {
  text-decoration: none;
  color: var(--light-1);
}

.link--primary {
  font-size: 1.4rem;
  font-weight: 700;
}

.link--secondary {
  font-size: 1.2rem;
  font-weight: 500;
}

.v-expansion-panel-title {
  padding-left: 0px !important;
}

v-list-item {
  min-height: 68px;
}

.mobile-menu__list-item {
  min-height: 68px !important;
  margin-bottom: 1rem;
}
</style>
