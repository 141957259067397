<template>
  <div class="page">
    <div class="container ">
      <!-- Sticky Sidebar -->
      <div class="sidebar">
        <ServiceSidebar />
      </div>

      <!-- Main Content -->
      <div class="content">
        <!-- Hero Section -->
        <section class="hero">
        <div class="hero-content">
            <h2>Roadmap My App: <br><span class="highlight">From Feedback To Growth</span></h2>
            
            <p>
              You’ve built an app, your customers love it, and feedback is rolling in—but what’s next? With DIGU’s Roadmap My App services, we help you shape the future of your app. From scaling to support more users to adding impactful new features or improving the user experience, our strategic planning ensures your app evolves and grows with your business.
            </p>
            <!-- <div class="hero-image">
              <img
                :src="require('@/assets/img/hero-roadmap.svg')"
                alt="UX/UI Design Services"
              />
            </div> -->
            <!-- Video Section -->
            <div class="hero-video">
              <v-dialog
                v-model="displayVideo"
                fullscreen
                :scrim="false"
                class="video-dialog"
              >
                <v-btn
                  icon
                  style="position: absolute; z-index: 10; top: 10px; right: 10px"
                  @click="displayVideo = !displayVideo"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <video controls autoplay loop muted playsinline id="video-background" ref="dialogVideoPlayer">
                  <source
                    :src="isMobile ? require('@/assets/video/RoadmapMyApp-mobile.mp4') : require('@/assets/video/RoadmapMyApp.mp4')"
                    type="video/mp4"
                  />
                </video>
              </v-dialog>

              <!-- Background Video -->
              <video autoplay loop muted playsinline id="video-background">
                <source
                  :src="isMobile ? require('@/assets/video/RoadmapMyApp-mobile.mp4') : require('@/assets/video/RoadmapMyApp.mp4')"
                  type="video/mp4"
                />
              </video>

              <!-- Watch Now Button -->
              <div class="video-overlay">
                <v-btn
                  size="large"
                  prepend-icon="mdi-play-circle-outline"
                  class="watch-now-btn"
                  @click="playVideo"
                  elevation="0"
                >
                  Watch Now
                </v-btn>
              </div>
            </div>
        </div>
        </section>


      <!-- Roadmap planning services -->
          <section class="implementation">
              <div class="implementation-content">
                  <h2>Efficient Code <br><span class="highlight">Implementation Services</span></h2>
                  <p>
                      Once your app’s code has been reviewed, we will implement the
                      necessary improvements efficiently, ensuring your app is optimized
                      for:
                  </p>
                  <div class="implementation-cards">
                      <div
                      v-for="(feature, index) in implementationFeatures"
                      :key="index"
                      class="implementation-card"
                      >
                      <div>
                          <img
                          :src="feature.icon"
                          :alt="feature.title"
                          />
                      </div>
                      <div>
                          <h4>{{ feature.title }}</h4>
                          <p>{{ feature.description }}</p>
                      </div>
                      </div>
                  </div>
              </div>

              <!-- wave container -->
              <div class="wave-container">
                <div class="wave-content">
                  <h2 >Comprehensive Application  <span class="highlight">Development Services</span></h2>
                  <p class="description">
                    We transform your roadmap into a scalable, user-focused app that supports growth and feature expansion.
                  </p>
                </div>
              </div>
          </section>

        <!-- Design Services section -->
        <section class="features">
          <div class="features-content">
            <h2>Why Choose DIGU For <br><span class="highlight">Your App Development?</span></h2>
            
            <div class="feature-cards">
              <div
                v-for="(feature, index) in whyDigu"
                :key="index"
                class="feature-card"
              >
                <img
                  :src="feature.icon"
                  :alt="feature.title"
                />
                <h4>{{ feature.title }}</h4>
                <p>{{ feature.description }}</p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div class="service-list">
            <MobileServiceList />
          </div>
        </section>


        <!-- Get in Touch Section -->
        <section>
          <div>
            <ScheduleNow dynamicText="to start planning your app’s future with DIGU!" />
          </div>
        </section>
      
          
      
      </div>

      
    </div>
  </div>
  
</template>

<script>
import ServiceSidebar from '../components/ServiceSidebar.vue';
import ScheduleNow from '../components/GetInTouch.vue';
import MobileServiceList from '../components/MobileServiceList.vue';

export default {
  name: 'DesignMyApp',
  components: {
    ServiceSidebar,
    ScheduleNow,
    MobileServiceList,
  },
  data() {
    return {
      whyDigu:[
          {
            icon: require('@/assets/img/scalability-icon.svg'),
            title: "Scalability",
            description:
              "Build a strong foundation for future updates and growth.",
          },
          {
            icon: require('@/assets/img/user-center.svg'),
            title: "User-Centered Design",
            description:
              "Create user-friendly apps that engage and retain users.",
          },
          {
            icon: require('@/assets/img/endToend-support.svg'),
            title: "End-to-End Support",
            description:
              "From roadmap planning to app deployment, we’re with you at every step.",
          },
          
        ],
        
        displayVideo: false,
        isMobile: false,
      implementationFeatures: [
            {
            icon: require('@/assets/img/actionable-strategies.svg'),
            title: 'Actionable Strategies',
            description: 'Define your app’s goals, core features, and development phases.',
            },
            {
            icon: require('@/assets/img/efficient-planning.svg'),
            title: 'Efficient Planning',
            description: 'Minimize risks and streamline the journey from concept to launch.',
            },
            {
            icon: require('@/assets/img/market-ready-focus.svg'),
            title: 'Market-Ready Focus',
            description: 'Ensure your app aligns with user needs and industry standards.',
            },
        ],
    };
  },
  methods: {
    playVideo() {
      this.displayVideo = true;
    },
    pauseVideo() {
      console.log('Pause Video');
    },
    checkScreenSize() {
      // Update isMobile based on screen width
      const wasAlreadyMobile = this.isMobile;
      this.isMobile = window.innerWidth <= 780;
      
      // If mobile state changed, update video sources
      if (wasAlreadyMobile !== this.isMobile) {
        this.$nextTick(() => {
          // Force video elements to reload with new source
          if (this.$refs.backgroundVideoPlayer) {
            const videoElement = this.$refs.backgroundVideoPlayer;
            videoElement.load();
            videoElement.play().catch(e => console.log('Auto-play prevented:', e));
          }
          
          if (this.displayVideo && this.$refs.dialogVideoPlayer) {
            const dialogVideo = this.$refs.dialogVideoPlayer;
            dialogVideo.load();
            dialogVideo.play().catch(e => console.log('Dialog video play prevented:', e));
          }
        });
      }
    },
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
           },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
    
  },
  created() {
    document.getElementById('loaderSection').classList.add('d-none');
    
  },
};
</script>

<style scoped>
.page {
  background-color: #F7F8FC;
}

section {
  width: 100%;
  margin-left: 2%;
}

.container {
  display: flex;
  max-width: 1450px;
  margin: 0rem auto;
  padding: 0 2rem 4rem;
  font-family: Arial, sans-serif;
}

/* Sidebar */
.sidebar {
  flex: 0 0 350px;
  position: -webkit-sticky;
  position: sticky;
  top: 150px;
  margin-top: 2rem;
  margin-left: 3%;
  z-index: 999;
}

@media (min-width: 931px) {
  .service-list {
    display: none;
  }
}

@media (max-width: 930px) {
  .service-list {
    display: block;
  }
}

/* Main Content */
.content {
  display: grid;
  flex-grow: 1;
  text-align: left;
}

/* Title Styling */
h2 {
  font-size: clamp(28px, 5vw, 44px);
  margin-bottom: 20px;
}

.highlight {
  color: #ff4d4d;
}

/* Hero Section */
.hero {
  margin-bottom: 40px;
}

.hero-content {
  padding: 3rem  5rem;
}

.hero p {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;
}

.hero-image img {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Hero Video Section */
.hero-video {
  position: relative;
  height: 400px; /* Default height for larger screens */
  overflow: hidden;
}

#video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire container */
}

.video-overlay {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 2; /* Ensure the button is above the video */
}

.watch-now-btn {
  background-color: var(--accent-red);
  color: #fff;
  text-transform: capitalize;
  letter-spacing: 0px;
  font-family: 'Outfit Medium', sans-serif;
  font-weight: 500;
}


/* Features Section */
.features {
  padding: 1rem;
  width: 84%;
  background-color: #e5f6ff;
  margin-left: 5.5rem;
  padding-bottom: 40px;
}



.feature-cards {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;
}

.feature-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
  max-width: 500px;
  transition: transform 0.3s ease-in-out;
}

.feature-card:hover {
  transform: translateY(-10px);
}

.feature-card img {
  width: 50px;
  margin-bottom: 20px;
}

.feature-card h4 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.feature-card p {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}


/* Implementation Section */
.implementation{
  width: 84%;
  background-color: #e5f6ff;
  margin-left: 5.5rem;
}

.implementation-content {
  margin: 2rem 1.5rem;
  
}

.implementation-cards {
  margin-top: 2rem;
}

.implementation-card {
  display: flex;
  padding: 1rem;
  margin: 1rem;
  height: 120px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.implementation-card::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #9dcaf5, #5eb3da);
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: scaleX(0.9);
}



.implementation-card:hover::after {
  opacity: 1;
  transform: scaleX(1);
}

.implementation-card img {
  margin-right: 1rem;
  width: 50px;
  height: auto;
}

/* Wave Container */
.wave-container {
  position: relative;
  width: 100%; /* Full width */
  margin: 0; /* Remove margin */
  /* margin-left: 4rem; */
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 40 800 300"><path d="M0,100 C50,50 150,150 300,100 C450,50 550,150 800,100 L800,300 L0,300 Z" fill="%23A3DEFF"/></svg>');
  background-repeat: no-repeat;
  background-size: cover; /* Scale SVG to fit container */
  padding-top: 6em; /* Add padding for the content */
  padding-bottom: 2rem;
}

.wave-content {
  padding: 5rem 2rem; /* Adjust padding for smaller screens */
  width: 100%;
}

/* Responsive Styles */
@media (max-width: 930px) {
  .sidebar {
    display: none;
  }

  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .content{
    padding: 5px;
  }

  .hero-content,
 
  .implementation-content {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 0;
    margin-right: 0;
  }
  .features, .implementation {
    width: 100%;
    margin: 0;

   
  }

  .wave-container {
    width: 100%;
    margin: 0; 
  }
  .feature-card{
    max-width: 300px;
  }

  .implementation-card {
    flex-direction: column; /* Stack content vertically on smaller screens */
    align-items: left; /* Center align items */
    text-align: left; /* Center text */
    height: auto; /* Adjust height for stacked content */
  }

  .implementation-card img {
    margin-right: 0; /* Remove right margin for smaller screens */
    margin-bottom: 1rem; /* Add spacing between image and text */
  }
  section{
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

/* Add responsiveness */
@media (max-width: 768px) {
  .wave-container {
    padding-bottom: 4rem;
  }
  
  .title {
    font-size: 2rem;
  }
  
  .description {
    font-size: 1.1rem;
  }
  .features-content{
    padding: 0;
  }
  .hero-video {
    height: 50vh; /* Use viewport height for better responsiveness */
    position: relative;
    overflow: hidden;
  }

  #video-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .video-overlay {
    bottom: 10px;
    left: 10px;
  }

  .watch-now-btn {
    font-size: 14px;
    padding: 8px 16px;
  }
}
video {
  /* Ensure all videos maintain aspect ratio */
  max-width: 100%;
  height: auto;
  /* iOS specific fix */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
</style>