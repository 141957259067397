<template>
<div class="page">
  <div class="container">
    <!-- Sidebar -->
    <div class="sidebar">
      <ServiceSidebar />
    </div>

    <!-- Main Content -->
    <div class="content">
      <!-- Hero Section -->
      <section class="hero">
        <div class="hero-content">
          <h2>Review My App: <span class="highlight">Application <br> Code Audit Services</span></h2>
          
          <p>
            Elevate your app’s performance, security, and scalability with
            DIGU’s Application Code Audit Services. Ideal for startups preparing
            to grow, this service offers a comprehensive review of your app’s
            code to optimize its structure, efficiency, and security.
          </p>
          <!-- <div class="hero-image">
            <img
              :src="require('@/assets/img/review-hero.svg')"
              alt="MVP Development Services"
            />
          </div> -->
          <!-- Video Section -->
          <div class="hero-video">
            <v-dialog
              v-model="displayVideo"
              fullscreen
              :scrim="false"
              class="video-dialog"
            >
              <v-btn
                icon
                style="position: absolute; z-index: 10; top: 10px; right: 10px"
                @click="displayVideo = !displayVideo"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <video controls autoplay loop muted playsinline id="video-background" ref="dialogVideoPlayer">
                <source
                  :src="isMobile ? require('@/assets/video/ReviewMyApp-mobile.mp4') : require('@/assets/video/ReviewMyApp.mp4')"
                  type="video/mp4"
                />
              </video>
            </v-dialog>

            <!-- Background Video -->
            <video autoplay loop muted playsinline id="video-background">
              <source
                :src="isMobile ? require('@/assets/video/ReviewMyApp-mobile.mp4') : require('@/assets/video/ReviewMyApp.mp4')"
                type="video/mp4"
              />
            </video>

            <!-- Watch Now Button -->
            <div class="video-overlay">
              <v-btn
                size="large"
                prepend-icon="mdi-play-circle-outline"
                class="watch-now-btn"
                @click="playVideo"
                elevation="0"
              >
                Watch Now
              </v-btn>
            </div>
          </div>
        </div>
      </section>

      <!-- Features Section -->
      <section class="features">
        <div class="features-content">
          <h2>Expert Code <span class="highlight">Review <br> Consultation Services</span></h2>
          <p>
            Uncover hidden inefficiencies and vulnerabilities with our expert
            code review consultations. We provide:
          </p>
          <div class="feature-cards">
            <div
              v-for="(feature, index) in mvpFeatures"
              :key="index"
              class="feature-card"
            >
              <img
                :src="feature.image"
                :alt="feature.title"
              />
              <h4>{{ feature.title }}</h4>
              <p>{{ feature.description }}</p>
            </div>
          </div>
        </div>
      </section>

      <!-- Efficient Code Implementation Services Section -->
      <section class="implementation">
        <div class="implementation-content">
          <h2>Efficient Code <br> <span class="highlight">Implementation Services</span></h2>
          <p>
            Once your app’s code has been reviewed, we will implement the
            necessary improvements efficiently, ensuring your app is optimized
            for:
          </p>
          <div class="implementation-cards">
            <div
              v-for="(feature, index) in implementationFeatures"
              :key="index"
              class="implementation-card"
            >
              <div>
                <img
                  :src="feature.icon"
                  :alt="feature.title"
                />
              </div>
              <div>
                <h4>{{ feature.title }}</h4>
                <p>{{ feature.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
          <div class="service-list">
            <MobileServiceList />
          </div>
      </section>

      <!-- Get in Touch Section -->
      <section>
        <div>
          <ScheduleNow dynamicText="to streamline your code with DIGU." />
        </div>
      </section>
      
          
      
      </div>

      
    </div>
  </div>
  
</template>

<script>
import ServiceSidebar from '../components/ServiceSidebar.vue';
import ScheduleNow from '../components/GetInTouch.vue';
import MobileServiceList from '../components/MobileServiceList.vue';

export default {
  name: 'DesignMyApp',
  components: {
    ServiceSidebar,
    ScheduleNow,
    MobileServiceList,
  },
  data() {
    return {
      displayVideo: false,
      isMobile: false,
      mvpFeatures: [
        {
          image: require('@/assets/img/indepth-analysis.svg'),
          title: 'In-Depth Analysis',
          description:
            'Identify areas for improvement in code structure and logic.',
        },
        {
          image: require('@/assets/img/security-enhancements.svg'),
          title: 'Security Enhancements',
          description:
            'Address potential vulnerabilities to protect your app and users.',
        },
        {
          image: require('@/assets/img/performance-optimization.svg'),
          title: 'Performance Optimization',
          description:
            'Ensure your app operates at its best while being ready to scale.',
        },
        {
          image: require('@/assets/img/compliance.svg'),
          title: 'Industry Standard Compliance',
          description:
            'Align your code with best practices for long-term success.',
        },
      ],
      implementationFeatures: [
        {
          icon: require('@/assets/img/peak-performance.svg'),
          title: 'Peak Performance',
          description: 'Faster load times and better user experience.',
        },
        {
          icon: require('@/assets/img/scalability.svg'),
          title: 'Scalability',
          description: 'A robust codebase that grows with your business.',
        },
        {
          icon: require('@/assets/img/reliability.svg'),
          title: 'Reliability',
          description: 'Stable and secure app operations.',
        },
      ],
    };
  },
  methods: {
    playVideo() {
      this.displayVideo = true;
    },
    pauseVideo() {
      console.log('Pause Video');
    },
    checkScreenSize() {
      // Update isMobile based on screen width
      const wasAlreadyMobile = this.isMobile;
      this.isMobile = window.innerWidth <= 780;
      
      // If mobile state changed, update video sources
      if (wasAlreadyMobile !== this.isMobile) {
        this.$nextTick(() => {
          // Force video elements to reload with new source
          if (this.$refs.backgroundVideoPlayer) {
            const videoElement = this.$refs.backgroundVideoPlayer;
            videoElement.load();
            videoElement.play().catch(e => console.log('Auto-play prevented:', e));
          }
          
          if (this.displayVideo && this.$refs.dialogVideoPlayer) {
            const dialogVideo = this.$refs.dialogVideoPlayer;
            dialogVideo.load();
            dialogVideo.play().catch(e => console.log('Dialog video play prevented:', e));
          }
        });
      }
    },
  },
  created() {
    document.getElementById('loaderSection').classList.add('d-none');
  },
};
</script>

<style scoped>
.page {
  background-color: #F7F8FC;
}

section {
  width: 100%;
  margin-left: 2%;

}

.container {
  display: flex;
  max-width: 1450px;
  margin: 0rem auto;
  padding: 0 2rem 4rem;
  font-family: Arial, sans-serif;
}

/* Sidebar */
.sidebar {
  flex: 0 0 350px;
  position: sticky;
  top: 150px;
  margin-top: 2rem;
  margin-left: 3%;
  z-index: 999;
}

@media (min-width: 931px) {
  .service-list {
    display: none;
  }
}

@media (max-width: 930px) {
  .service-list {
    display: block;
  }
}

/* Main Content */
.content {
  display: grid;
  flex-grow: 1;
  text-align: left;
}

/* Hero Section */

.hero-content {
  padding:2rem 5rem;
  padding-top: 3rem;
}

h2 {
  font-size: clamp(28px, 5vw, 44px);
  margin-bottom: 20px;
}

.highlight {
  color: #ff4d4d;
}

.hero p {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;
}

.hero-image img {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Hero Video Section */
.hero-video {
  position: relative;
  height: 400px; /* Default height for larger screens */
  overflow: hidden;
}

#video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire container */
}

.video-overlay {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 2; /* Ensure the button is above the video */
}

.watch-now-btn {
  background-color: var(--accent-red);
  color: #fff;
  text-transform: capitalize;
  letter-spacing: 0px;
  font-family: 'Outfit Medium', sans-serif;
  font-weight: 500;
}


/* Features Section */
.features {
  width: 87%;
  background-color: #e5f6ff;
  margin-left: 5rem;
  margin-top: 40px;
  margin-bottom: 40px;
}

.features-content {
  margin: 2rem 1.5rem;
}

.feature-cards {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;
}

.feature-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
  max-width: 300px;
  transition: transform 0.3s ease-in-out;
}

.feature-card:hover {
  transform: translateY(-10px);
}

.feature-card img {
  width: 50px;
  margin-bottom: 20px;
}

.feature-card h4 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.feature-card p {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}

/* Implementation Section */


.implementation-content {
  margin: 1rem 5rem;
}

.implementation-cards {
  margin-top: 2rem;
}

.implementation-card {
  display: flex;
  padding: 1rem;
  margin: 1rem;
  height: 120px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.implementation-card::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #9dcaf5, #5eb3da);
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: scaleX(0.9);
}

.implementation-card:hover::after {
  opacity: 1;
  transform: scaleX(1);
}

.implementation-card img {
  margin-right: 1rem;
  width: 50px;
  height: auto;
}

/* Responsive Styles */
@media (max-width: 930px) {
  .sidebar {
    display: none;
  }

  .container {
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
  }

  .content{
    padding: 5px;
  }

  .hero-content,
  .features-content,
  .implementation-content {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 0;
    margin-right: 0;
  }
  .features{
    margin: 0;
    margin: 3rem 0rem;
    padding: 0;
    width: 100%;
  }
  section{
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .implementation-card {
    flex-direction: column;
    align-items: left;
    text-align: left;
    height: auto;
  }

  .implementation-card img {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

/* Add responsiveness */
@media (max-width: 768px) {
  .wave-container {
    padding-bottom: 4rem;
  }
  
  .title {
    font-size: 2rem;
  }
  
  .description {
    font-size: 1.1rem;
  }
  .features-content{
    padding: 0;
  }
  .hero-video {
    height: 50vh; /* Use viewport height for better responsiveness */
    position: relative;
    overflow: hidden;
  }

  #video-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .video-overlay {
    bottom: 10px;
    left: 10px;
  }

  .watch-now-btn {
    font-size: 14px;
    padding: 8px 16px;
  }
}
video {
  /* Ensure all videos maintain aspect ratio */
  max-width: 100%;
  height: auto;
  /* iOS specific fix */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
</style>