import axios from "axios";
axios.defaults.timeout = 10000;
axios.defaults.headers.post["Content-Type"] = "multipart/form-data";

// ? Create a Job Application
const createApplication = async (application) => {
  const apiUrl = process.env.VUE_APP_API_URL;

  const formData = new FormData();
  formData.append("name", application.name);
  formData.append("email", application.email);
  formData.append("role", application.role);
  formData.append("phone", `+977${application.phone}`);
  formData.append("location", application.location);
  formData.append("github", application.github);
  formData.append("linkedin", application.linkedin);
  formData.append("languages", JSON.stringify(application.languages));
  formData.append("frameworks", JSON.stringify(application.frameworks));
  formData.append("softwares", JSON.stringify(application.softwares));
  formData.append("message", application.message);
  formData.append("cv", application.cv[0]);

  const applicationRes = axios
    .post(`${apiUrl}/v1/public/job-application`, formData)
    .then((response) => {
      console.log(response);
      return response;
    });

  return applicationRes;
};

export default {
  createApplication,
};
