<template>
  <section class="mission-vision-section">
    <div class="section-container">
      <div class="content-container">
        <!-- Left Side: Mission -->
        <div class="text-container mission">
          <h2 class="title">Our Mission</h2>
          <p class="description">Make Nepal the premier hub for tech professionals.</p>
        </div>

        <!-- Center: Image with Animated Ring -->
        <div class="image-container">
          <div class="blue-line "></div>
          <div class="blue-line-mob"></div>
          <div class="image-circle-outer">
            <div class="image-circle-inner">
              <img :src="require('@/assets/img/missionAndVision.svg')" alt="Mission and Vision" class="center-image" />
              <img :src="require('@/assets/img/missionAndVision-mobile.svg')" alt="Mission and Vision" class="center-image-mobile" />
            </div>
            <div class="rotating-ring"></div>
            <!-- <div class="dot dot-left"></div>
            <div class="dot dot-right"></div> -->
          </div>
        </div>

        <!-- Right Side: Vision -->
        <div class="text-container vision">
          <h2 class="title">Our Vision</h2>
          <p class="description">Empower Nepali professionals to deliver world-class software services while driving success for our customers.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MissionAndVision',
};
</script>

<style scoped>
.mission-vision-section {
  padding: 5rem 2rem;
  overflow: hidden; 
  background: #ffffff;
}

.section-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.content-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 2rem;
}

.text-container {
  align-items: center;
  flex: 2;
  /* min-width: 250px; */
  display: flex;
  flex-direction: column;
  position: relative;
}

.mission {
  text-align: right;
  align-items: flex-end;
  margin: 0 2rem;
  max-height: 150px;

}

.vision {
  text-align: left;
  align-items: flex-start;
  margin: 0 2rem;
  /* margin-top: 5rem; */
  max-height: 150px;

}

.title {
  font-size: 28px;
  font-weight: 700;
  color: #2d3748;
  margin-bottom: 4rem;
  position: relative;
  z-index: 3;
}

.description {
  font-size: 16px;
  line-height: 1.6;
  color: #4a5568;
  /* margin-top: 6rem; */
  position: relative;
  z-index: 10;
}

/* Image and Circle Styles */
.image-container {
  flex: 3;
  margin: 1rem;
  position: relative;
  transform: translateZ(0); /* GPU layer creation */
}

.blue-line {
  display: block;
  position: absolute;
  top: 50%;
  left: -100%;
  right: -100%;
  height: 2px;
  background: #00a0e4;
  z-index: 1;
  transform: translateY(-50%);
  border-radius: 50px; /* Makes ends rounded */
  overflow: visible;
}

.blue-line-mob{
  display: none;
}

.image-circle-outer {
  position: relative;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: linear-gradient(145deg, #00a0e4, #0072c6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  isolation: isolate; /* Prevent z-index conflicts */
  
  
}

.image-circle-inner {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 8px solid white;
  position: relative;

  z-index: 3;
}

.center-image,
.center-image-mobile {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.center-image-mobile {
  display: none;
}

.rotating-ring {
  position: absolute;
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  border: 8px solid transparent;
  border-top: 8px solid #00a0e4;
  border-bottom: 8px solid #0072c6;
  border-radius: 50%;
  animation: rotate 8s linear infinite;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform; /* Optimization hint */
  transform: translate3d(0,0,0);

}


@keyframes rotate {
  0% { transform: rotate(0deg) translateZ(0); }
  100% { transform: rotate(360deg) translateZ(0); }
}

@media (max-width: 1012px) {
  .content-container {
    flex-direction: column;
  }

  .text-container {
    padding: 1rem 0;
    text-align: center;
    align-items: center;
    max-width: 100%;
  }
  .blue-line{
    display: none;
  }
 
  .title{
    margin: 1rem;
  }
  .mission, .vision {
    text-align: center;
  }

  .image-container {
    margin: 2rem 0;
  }
  
  


  .blue-line-mob {
    display: block;
    position: absolute;
    left: 50%;
    top: -80px;
    bottom: -80px;
    width: 2px;
    background: #00a0e4;
    z-index: -1;
    transform: translateX(-50%) ;
    transform-origin: center;
  }

  .center-image {
    display: none;
  }

  .center-image-mobile {
    display: block;
  }
  .image-circle-outer {
    z-index: 2; /* New stacking context */
  }

  .rotating-ring {
    z-index: 3; /* Ensure ring stays above line */
    transform: translate3d(0,0,0) rotate(0);
    -webkit-transform-style: preserve-3d; /* iOS specific */
  }
  
}
@media (max-width: 780px){
  /* .rotating-ring{} */
  .image-circle-inner {
    -webkit-overflow-scrolling: touch; /* Better scroll handling */
    width: 200px;
    height: 200px;
  }
  .image-circle-outer {
    width: 200px;
    height: 200px;
    
  }
  .rotating-ring {
    animation: rotate 8s linear infinite;
    -webkit-animation: rotate 8s linear infinite;
  }
}

@media (max-width:455px ){
  .vision {
    margin-bottom: 4rem;
  }
  .mission{
    margin-bottom: 2rem;
  }
  

}
@media (max-width:368px ){
  .vision {
    margin-bottom: 4rem;
  }
  .blue-line-mob{
    
    margin-top: 2rem;
  }

}
</style>