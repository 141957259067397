<template>
  <div class="page">
    <div class="container">
      <!-- Sticky Sidebar -->
      <div class="sidebar">
        <ServiceSidebar />
      </div>

      <!-- Main Content -->
      <div class="content">
        <!-- Hero Section with Video -->
        <section class="hero">
          <div class="hero-content">
            <h2>Staff Augmentation: <br><span class="highlight">Onboard Top Talent</span></h2>
            <p>
              Empower your startup with DIGU’s staff augmentation services, enabling you to onboard skilled developers, QA engineers, project managers, and UX/UI designers seamlessly. Our subscription-based model ensures smooth team integration, scalability, and exceptional results without the delays or high costs of traditional hiring.
            </p>
            <p>
              <router-link
                class="highlight page-link"
                to="/verified-badges"
              >
                Learn more about verified professionals
                <v-icon size="x-small" class="ms-2">mdi-open-in-new</v-icon>
              </router-link>
            </p>
            <!-- Video Section -->
            <div class="hero-video">
              <v-dialog
                v-model="displayHeroVideo"
                fullscreen
                :scrim="false"
                class="video-dialog"
              >
                <v-btn
                  icon
                  style="position: absolute; z-index: 10; top: 10px; right: 10px"
                  @click="displayHeroVideo = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <video controls autoplay  muted playsinline id="video-background" ref="heroDialogVideoPlayer">
                  <source
                    :src="isMobile ? require('@/assets/video/StaffAugmentation-mobile.mp4') : require('@/assets/video/StaffAugmentation.mp4')"
                    type="video/mp4"
                  />
                </video>
              </v-dialog>

              <!-- Background Video -->
              <video autoplay loop muted playsinline id="video-background">
                <source
                  :src="isMobile ? require('@/assets/video/StaffAugmentation-mobile.mp4') : require('@/assets/video/StaffAugmentation.mp4')"
                  type="video/mp4"
                />
              </video>

              <!-- Watch Now Button -->
              <div class="video-overlay">
                <v-btn
                  size="large"
                  prepend-icon="mdi-play-circle-outline"
                  class="watch-now-btn"
                  @click="playHeroVideo"
                  elevation="0"
                >
                  Watch Now
                </v-btn>
              </div>
            </div>
          </div>
        </section>

        <!-- Business Growth Section -->
        <section class="business-growth">
          <div class="content-container">
            <h2>Expert Talent For<br><span class="highlight">Every Phase Of Your Project</span></h2>
            <div class="main-content">
              <!-- Content Display (Image) for Desktop -->
              <div class="content-display">
                <div class="content-image">
                  <transition name="fade">
                    <img :key="activeIndex" :src="activeImage" alt="Business Growth Image" />
                  </transition>
                </div>
              </div>

              <!-- Titles Container -->
              <div class="titles-container">
                <div
                  v-for="(point, index) in contentDescription"
                  :key="index"
                  class="title-wrapper"
                  :class="{
                    'active': index === activeIndex,
                    'animated': index === animatingIndex && !isPaused
                  }"
                  @click="handleClick(index)"
                >
                  <div class="title-content">
                    <h4>{{ point.title }}</h4>
                    <div class="description" v-if="index === activeIndex">
                      {{ point.description }}
                      <!-- Content Display (Image) for Mobile -->
                      <div class="content-image mobile-image">
                        <transition name="fade">
                          <img :key="activeIndex" :src="activeImage" alt="Business Growth Image" />
                        </transition>
                      </div>
                    </div>
                  </div>
                  <div class="underline">
                    <div class="underline-progress" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Wave Container -->
          <div class="wave-container">
            <div class="wave-content">
              <div class="wave-description">
                <h2 class="wave-title">Building Bridges, Closing Gaps: <br><span class="highlight">Empowering Digital Transformation</span></h2>
                
                <!-- Video Section -->
                <div class="hero-video">
                  <v-dialog
                    v-model="displayWaveVideo"
                    fullscreen
                    :scrim="false"
                    class="video-dialog"
                  >
                    <v-btn
                      icon
                      style="position: absolute; z-index: 10; top: 10px; right: 10px"
                      @click="displayWaveVideo = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <video controls autoplay id="video-background" ref="waveDialogVideoPlayer">
                      <source
                        :src="isMobile ? require('@/assets/video/AboutUsVideoMobile.mp4') : require('@/assets/video/AboutUsVideo.mp4')"
                        type="video/mp4"
                      />
                    </video>
                  </v-dialog>

                  <!-- Background Video -->
                  <video autoplay loop muted playsinline id="video-background">
                    <source
                      :src="isMobile ? require('@/assets/video/AboutUsVideoMobile.mp4') : require('@/assets/video/AboutUsVideo.mp4')"
                      type="video/mp4"
                    />
                  </video>

                  <!-- Watch Now Button -->
                  <div class="video-overlay">
                    <v-btn
                      size="large"
                      prepend-icon="mdi-play-circle-outline"
                      class="watch-now-btn"
                      @click="playWaveVideo"
                      elevation="0"
                    >
                      Watch Now
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- Features Section -->
        <section class="features">
          <div class="features-content">
            <h2>Why Choose DIGU's <br><span class="highlight">Staff Augmentation Services?</span></h2>
            <div class="feature-cards">
              <div
                v-for="(feature, index) in whyDigu"
                :key="index"
                class="feature-card"
              >
                <img
                  :src="feature.icon"
                  :alt="feature.title"
                />
                <h4>{{ feature.title }}</h4>
                <p>{{ feature.description }}</p>
              </div>
            </div>
          </div>
        </section>


        <section>
          <div class="service-list">
            <MobileServiceList />
          </div>
        </section>
        <!-- Get in Touch Section -->
        <section>
          <div>
            <ScheduleNow dynamicText="and discover how our staff augmentation services can transform your team." />
          </div>
        </section>
        
          
      
      </div>

      
    </div>
  </div>
  
</template>

<script>
import ServiceSidebar from '../components/ServiceSidebar.vue';
import ScheduleNow from '../components/GetInTouch.vue';
import MobileServiceList from '../components/MobileServiceList.vue';

export default {
  name: 'DesignMyApp',
  components: {
    ServiceSidebar,
    ScheduleNow,
    MobileServiceList,
  },
  data() {
    return {
      whyDigu: [
        {
          icon: require('@/assets/img/scalable-solution.svg'),
          title: "Scalable Solutions",
          description: "Scale your team up or down as your project evolves.",
        },
        {
          icon: require('@/assets/img/cost-effective.svg'),
          title: "Cost-Effective",
          description: "Save on recruitment costs and reduce time-to-hire.",
        },
        {
          icon: require('@/assets/img/immediate-integration.svg'),
          title: "Immediate Integration",
          description: "Get expert talent without delays, ensuring a smooth workflow.",
        },
      ],
      displayHeroVideo: false,
      displayWaveVideo: false,
      isMobile: false,
      activeIndex: 0,
      animatingIndex: 0,
      isPaused: false,
      animationInterval: null,
      animationDuration: 6000, // 6 seconds per title
      contentDescription: [
        {
          title: "Expert Developers",
          description: "Boost your development capabilities with proficient developers skilled in the latest technologies.",
          image: require("@/assets/img/businessGrowth.svg"),
        },
        {
          title: "QA Engineers",
          description: "Ensure product quality with meticulous QA engineers experienced in modern testing frameworks.",
          image: require("@/assets/img/qa-engineer.svg"),
        },
        {
          title: "Project Managers",
          description: "Streamline your projects with experienced managers ensuring on-time delivery and exceptional results.",
          image: require("@/assets/img/project-manager.svg"),
        },
        {
          title: "UX/UI Designers",
          description: "Enhance user experience with innovative designs tailored to your business needs.",
          image: require("@/assets/img/expert-developer.svg"),
        },
      ],
    };
  },
  computed: {
    activeImage() {
      return this.contentDescription[this.activeIndex].image;
    },
  },
  methods: {
    handleClick(index) {
      this.isPaused = true;
      this.activeIndex = index;
      this.animatingIndex = index;

      if (this.animationInterval) {
        clearInterval(this.animationInterval);
      }

      setTimeout(() => {
        if (this.isPaused) {
          this.startAnimation();
          this.isPaused = false;
        }
      }, this.animationDuration);
    },
    startAnimation() {
      if (this.animationInterval) {
        clearInterval(this.animationInterval);
      }

      this.animationInterval = setInterval(() => {
        if (!this.isPaused) {
          this.animatingIndex = (this.animatingIndex + 1) % this.contentDescription.length;
          this.activeIndex = this.animatingIndex;
        }
      }, this.animationDuration);
    },
    playHeroVideo() {
      this.displayHeroVideo = true;
    },
    playWaveVideo() {
      this.displayWaveVideo = true;
    },
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 780;
    },
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  created() {
    document.getElementById('loaderSection').classList.add('d-none');
  },
};
</script>

<style scoped>
.page {
  background-color: #F7F8FC;
}

section {
  width: 100%;
  margin-left: 2%;
}

.container {
  display: flex;
  max-width: 1450px;
  margin: 0rem auto;
  padding: 0 2rem 4rem;
  font-family: Arial, sans-serif;
}

/* Sidebar */
.sidebar {
  flex: 0 0 350px;
  position: sticky;
  top: 150px;
  margin-top: 2rem;
  margin-left: 3%;
  z-index: 999;
}

@media (min-width: 931px) {
  .service-list {
    display: none;
  }
}

@media (max-width: 930px) {
  .service-list {
    display: block;
  }
}
/* Main Content */
.content {
  display: grid;
  flex-grow: 1;
  text-align: left;
}

h2 {
  font-size: clamp(28px, 5vw, 44px);
  margin-bottom: 20px;
}

.highlight {
  color: #ff4d4d;
}
.page-link{
  text-decoration: none;
}

/* Hero Section */
.hero {
  margin-bottom: 40px;
}

.hero-content {
  padding:0  5rem;
  padding-top: 3rem;
}

.hero-image img {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}


.hero p {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Hero Video Section */
.hero-video {
  position: relative;
  height: 400px; /* Default height for larger screens */
  overflow: hidden;
}

#video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire container */
}

.video-overlay {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 2; /* Ensure the button is above the video */
}

.watch-now-btn {
  background-color: var(--accent-red);
  color: #fff;
  text-transform: capitalize;
  letter-spacing: 0px;
  font-family: 'Outfit Medium', sans-serif;
  font-weight: 500;
}


/* Business Growth Section */
.business-growth {
  margin-left: 5rem;
  width: 85%;
  background-color: #e5f6ff;
  margin-top: 40px;
}

.content-container {
  margin: 2rem 1.5rem;
  margin-bottom: 5rem;
}

.main-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.titles-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.title-wrapper {
  position: relative;
  cursor: pointer;
}

.title-content {
  padding: 0.2rem;
}

h4 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.description {
  font-size: 1rem;
  color: #666;
  margin-top: 1rem;
  line-height: 1.6;
}

.underline {
  position: relative;
  height: 3px;
  background-color: #e0e0e0;
  width: 100%;
  overflow: hidden;
}

.underline-progress {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #007bff;
  transform: translateX(-100%); /* Start hidden */
  transition: transform 0.3s ease-in-out; /* Smooth transition */
  opacity: 0;
}

.active .underline-progress {
  transform: translateX(0); /* Fully visible when active */
  opacity: 1;
}

.animated .underline-progress {
  animation: progressLine 6s linear; /* Animation for progress */
  opacity: 1;
}

@keyframes progressLine {
  from {
    transform: translateX(-100%); /* Start hidden */
  }
  to {
    transform: translateX(0); /* End fully visible */
  }
}

.content-image {
  position: sticky;
  top: 2rem;
}

.content-image img {
  width: 100%;
  border-radius: 8px;
}

/* Hide mobile image by default */
.mobile-image {
  display: none;
}

/* Wave Container */
.wave-container {
  position: relative;
  width: 100%;
  margin: 0rem;
 
  background: #A3DEFF;
  overflow: hidden;
}

.wave-content {
  padding: 2rem;
  width: 100%;
}

.wave-description {
  position: relative;
  padding-bottom: 1rem;
  z-index: 2;
}

.wave-description img {
  max-width: 100%;
  height: 300px;
  margin: 0rem auto;
  display: block;
}

/* Features Section */
.features {
  margin-top: 40px;
}

.features-content {
  margin: 1rem 5rem;
}

.feature-cards {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;
}

.feature-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
  max-width: 300px;
  transition: transform 0.3s ease-in-out;
}

.feature-card:hover {
  transform: translateY(-10px);
}

.feature-card img {
  width: 50px;
  margin-bottom: 20px;
}

.feature-card h4 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.feature-card p {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}

/* Responsive Styles */
@media (max-width: 930px) {
  .sidebar {
    display: none;
  }

  .container {
    padding-left: 1rem;
    padding-right: 1rem;
    
    width: 100%;
  }

  .business-growth {
    margin: 0;
    width: 100%;
    
  }

  .content {
    padding: 5px;
  }

  .hero-content,
  .features-content,
  .content-container {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 0;
    margin-right: 0;
  }
  .hero-content{
    padding-bottom: 2rem;
  }
  .wave-container {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  section {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  

  
}
@media (max-width: 780px){
  .main-content {
    grid-template-columns: 1fr;
  }
  /* Hide desktop image in mobile view */
  .content-display {
    display: none;
  }

  /* Show mobile image inside titles-container */
  .mobile-image {
    display: block;
    margin-top: 1rem;
    align-items: left;
    justify-content: left;
    
  }
  .mobile-image img{
    width:100%;
    height: 310px;
  }

  .titles-container {
    order: 0; /* Ensure titles-container is above the image */
    align-items: left;
    align-content: left;
  }

  .title-wrapper {
    margin-bottom: 1rem; /* Add some space between title-content and underline */
  }

  .underline {
    order: 2; /* Ensure underline is below the image */
  }

  .title-content {
    position: relative;
  }

  .description {
    margin-bottom: 1rem; /* Add some space between description and image */
  }
  .hero-video {
    height: 50vh; /* Use viewport height for better responsiveness */
    position: relative;
    overflow: hidden;
  }

  #video-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .video-overlay {
    bottom: 10px;
    left: 10px;
  }

  .watch-now-btn {
    font-size: 14px;
    padding: 8px 16px;
  }

}

video {
  /* Ensure all videos maintain aspect ratio */
  max-width: 100%;
  height: auto;
  /* iOS specific fix */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
</style>