<template>
  <section class="neu-hero-sektion">
    <v-dialog
      v-model="displayVideo"
      fullscreen
      :scrim="false"
      class="video-dialog"
    >
      <v-btn
        icon
        style="position: absolute; z-index: 10; top: 10px; right: 10px"
        @click="displayVideo = !displayVideo"
        ><v-icon>mdi-close</v-icon></v-btn
      >
      <video controls autoplay id="video-background" ref="dialogVideoPlayer">
        <source
          :src="require('@/assets/video/DIGU_About-us-Page.mp4')"
          type="video/mp4"
          v-if="!isPhone"
        />
        <source
          :src="require('@/assets/video/DIGU_About-us-Page.mp4')"
          type="video/mp4"
          v-else
        />
      </video>
    </v-dialog>
    <video autoplay loop muted playsinline id="video-background">
      <source
        :src="require('@/assets/video/DIGU_About-us-Page.mp4')"
        type="video/mp4"
        v-if="!isPhone"
      />
      <source
        :src="require('@/assets/video/DIGU_About-us-Page.mp4')"
        type="video/mp4"
        v-else
      />
    </video>
    <div class="content">
      <div class="content-sub">
        <div class="title-sub-title">
          <!-- <p class="sub-title">About Us</p> -->
          <h2 class="title">
            Your Growth <br />
            Our Mission
          </h2>
        </div>
        <div class="action">
          <v-btn
            size="large"
            prepend-icon="mdi-play-circle-outline"
            class="action-btn"
            @click="playVideo"
            elevation="0"
            >Watch Now</v-btn
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'NeuHeroSektion',
  data: () => ({
    displayVideo: false,
    isMob: false,
  }),

  created() {
    if (navigator.userAgent.includes('Mobile')) {
      this.isPhone = true;
    }
  },
  methods: {
    playVideo() {
      this.displayVideo = true;
    },
    pauseVideo() {
      console.log('Pause Video');
    },
    checkScreenSize() {
      // Update isMobile based on screen width
      const wasAlreadyMobile = this.isMobile;
      this.isMobile = window.innerWidth <= 780;
      
      // If mobile state changed, update video sources
      if (wasAlreadyMobile !== this.isMobile) {
        this.$nextTick(() => {
          // Force video elements to reload with new source
          if (this.$refs.backgroundVideoPlayer) {
            const videoElement = this.$refs.backgroundVideoPlayer;
            videoElement.load();
            videoElement.play().catch(e => console.log('Auto-play prevented:', e));
          }
          
          if (this.displayVideo && this.$refs.dialogVideoPlayer) {
            const dialogVideo = this.$refs.dialogVideoPlayer;
            dialogVideo.load();
            dialogVideo.play().catch(e => console.log('Dialog video play prevented:', e));
          }
        });
      }
    },
  },
  watch: {
    displayVideo(value, oldValue) {
      if (value === false && oldValue === true) {
        console.log('Closed Dialog After Open');
        this.videoPaused = true;
      }
    },
  },
};
</script>

<style scoped>
.neu-hero-sektion {
  height: 70vh;
  overflow: hidden;
  position: relative;
}

@media only screen and (min-width: 1024px) {
  .neu-hero-sektion {
    height: 90vh;
  }
}

#video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  position: relative;
  display: grid;

  z-index: 100;
  background-color: #14152c79;
  height: 100%;
  padding: 3rem;
  display: grid;
  align-items: end;
}

.content-sub {
  text-align: left;
}

@media only screen and (min-width: 1024px) {
  .content-sub {
    margin-left: 5rem;
    margin-bottom: 5rem;
  }
}

.title-sub-title {
  border-left: 3px solid #fff;
  padding-left: 2rem;
  color: #fff;
  margin-bottom: 1rem;
}

.title {
  font-family: 'Work Sans SemiBold';
  font-size: 1.75rem;
  line-height: 2rem;
}

@media only screen and (min-width: 1024px) {
  .title {
    font-size: 3rem;
    line-height: 3.25rem;
  }
}

.sub-title {
  font-family: 'Outfit SemiBold', sans-serif;
  font-size: 1rem;
  line-height: 1.25rem;
}

@media only screen and (min-width: 1024px) {
  .sub-title {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
}

.action-btn {
  background-color: var(--accent-red);
  color: #fff;
  text-transform: capitalize;
  letter-spacing: 0px;
  font-family: 'Outfit Medium', sans-serif;
  margin-left: 2rem;
  font-weight: 500;
}

.video-container {
  background-color: var(--primary-color-darken);
}

.video-player {
  width: 100%;
}

.close-btn {
  position: absolute;
}

video {
  /* Ensure all videos maintain aspect ratio */
  max-width: 100%;
  height: auto;
  /* iOS specific fix */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
</style>
