<template>
  <section class="aim-section">
    <div class="section__container">
      <v-row class="aim__row">
        <v-col
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="6"
          class="section__col aim__img"
        >
          <keep-alive>
            <v-img
              class="aim__image"
              cover
              :eager="true"
              :lazy-src="require('@/assets/img/lazy-img.jpg')"
              :src="require('@/assets/img/home-why-we-exist.webp')"
            > 
              <template v-slot:placeholder>
                <div class="d-flex align-center justify-center fill-height">
                  <v-progress-circular
                    color="grey-lighten-4"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </template>
            </v-img>
          </keep-alive>
        </v-col>
        <v-col
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="6"
          class="aim__text section__col"
        >
          <div class="content__wrapper">
            <p class="o600 aim__subtitle section__subtitle-2">Why We <span class="underline">Exist</span></p>
            <keep-alive>
              <v-img
                class="mobile-aim__image"
                cover
                :eager="true"
                :lazy-src="require('@/assets/img/lazy-img.jpg')"
                :src="require('@/assets/img/home_why-we-exist_neu.webp')"
              >
                <template v-slot:placeholder>
                  <div class="d-flex align-center justify-center fill-height">
                    <v-progress-circular
                      color="grey-lighten-4"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </template>
              </v-img>
            </keep-alive>
            <p class="m500 aim__description section__description-2">
              <!-- Our roots originate in Nepal - a beautiful country with limited
              career opportunities. This lack of opportunity drives Nepali
              professionals to look for tech jobs outside of Nepal. -->

              DIGU began with a simple idea: what if Nepali professionals didn’t have to leave their families for better jobs, and startups could still build amazing products without breaking the bank? There’s a lot more to this story, and we’d love to share it with you.
            </p>
            <!-- <p class="m500 aim__description section__description-2">
              On the other side, global companies are facing challenges in
              finding suitable, highly skilled candidates without breaking the
              bank. DIGU strives to solve both by empowering and elevating the
              lives of current and next generation Nepali professionals.
            </p> -->

            <div class="aim__btn-container">
              <v-btn
                class="aim__button o500"
                elevation="0"
                size="x-large"
                href="/about"
              >
                Get the full story
                <v-icon class="aim__button-icon">mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>

    <!-- PARTNER SECTION -->
    <!-- Desktop Partners -->
    <div class="partners-card">
      <v-row justify="center">
        <v-col cols="2" class="title__section">
          <p class="ws500 partners__title">Partners</p>
        </v-col>
        <v-col cols="8" sm="12" md="10" lg="10" class="logo__section">
          <v-img
            v-for="(partner, index) in partners"
            :key="index"
            class="partners__logo"
            @click="openPartner(partner.url)"
            :src="partner.src"
          />
        </v-col>
      </v-row>
    </div>

    <!-- Mobile Partners (Swiper) -->
    <div class="partner-card--mobile mt-4">
      <h3 class="mobile-partner__title my-5">Our Partners</h3>
      <swiper-container
        :slides-per-view="1"
        :space-between="spaceBetween"
        :centered-slides="true"
        :navigation="false"
        :pagination="false"
        :free-mode="true"
        :lazy="false"
        :loop="true"
        :cssMode="true"
        :autoplay="{
          delay: 1500,
          disableOnInteraction: false
        }"
        class="mobile-partner-slider"
      >
        <swiper-slide
          v-for="(partner, index) in partners"
          :key="index"
          :virtual="true"
        >
          <v-img
            class="partners__logo"
            :src="partner.src"
            @click="openPartner(partner.url)"
            :eager="true"
          />
        </swiper-slide>
      </swiper-container>
    </div>
  </section>
</template>

<script>
import { register } from 'swiper/element/bundle';
register();

export default {
  name: 'AimSection',
  data() {
    return {
      spaceBetween: 30,
      partners: [
        {
          src: require('@/assets/img/Okta.webp'),
          url: 'https://www.okta.com/',
        },
        {
          src: require('@/assets/img/Anthropic-Identity_Logo_Website.webp'),
          url: 'https://www.anthropicidentity.com/',
        },
        {
          src: require('@/assets/img/CAB.webp'),
          url: 'https://www.cab.edu.np/',
        },
        {
          src: require('@/assets/img/BlinkNow.webp'),
          url: 'https://blinknow.org/',
        },
        {
          src: require('@/assets/img/Beamlab-logo.webp'),
          url: 'https://beamlab.co/',
        },
      ],
    };
  },
  methods: {
    openPartner(url) {
      window.open(url, '_blank');
    },
  },
  created() {
    // Set screen dimensions if needed
    this.screen = {
      width: window.screen.width,
      height: window.screen.height,
    };
  },
  mounted() {
    let slider = document.querySelector('.swiper-wrapper');
    slider;
  }
};
</script>

<style scoped>
.aim-section {
  position: relative; /* Needed for absolute positioning of partners-card */
  display: block;
    padding-block: 4rem 10rem;

  background: #CFECFC;
}

@media only screen and (min-width: 1200px) {
  .aim-section {
    padding-block: 8rem 12rem;
  }
}

.aim__row {
  padding-inline: 0px;
}

.aim__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (min-width: 1280px) {
  .aim__text {
    padding-inline: 2rem 0px;
  }
}

.aim__subtitle,
.aim__title {
  text-align: left;
}

@media only screen and (min-width: 1200px) {
  .aim__subtitle,
  .aim__title {
    text-align: left;
  }
}

.aim__subtitle {
  color: #17171F;
  margin-bottom: 0.75rem;
  /* font-size: 56px !important; */
  font-size: clamp(32px, 5vw, 56px) !important;
}

.aim__title {
  color: var(--dark-3);
  line-height: 110%;
  margin-bottom: 0.5rem;
}

.aim__description {
  text-align: left;
  font-size: 1;
  color: var(--dark-2);
  margin-bottom: 1rem;
  width: 100%;
}

@media only screen and (min-width: 1200px) {
  .aim__description {
    text-align: left;
  }
}

.aim__btn-container {
  text-align: left;
  margin-top: 3rem;
}

.aim__button {
  background-color: #007AB2;
  color: white;
  text-transform: capitalize;
  letter-spacing: 0px;
  font-size: 1rem;
  text-align: left;
  border-radius:10px;
  position: relative;
  padding-right: 50px !important; /* Space for the arrow */
  transition: all 0.3s ease;
}

.aim__button {
  background-color: #007AB2;
  color: white;
  text-transform: capitalize;
  letter-spacing: 0px;
  font-size: 1rem;
  border-radius: 10px;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding-right: 24px !important;
}

.aim__button-icon {
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.3s ease;
  font-size: 1.25rem;
  margin-left: 4px;
}

.aim__button:hover .aim__button-icon {
  opacity: 1;
  transform: translateX(0);
}

.aim__img {
  display: none;
}

.underline {
  position: relative;
  display: inline-block;
}

.underline::after {
   content: "";
  position: absolute;
  left: 50%;
  bottom: -5px;
  width: 100%;
  height: 10px;
  background-image: url("@/assets/img/underline.svg"); /* Using alias */
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateX(-50%);
}

@media only screen and (min-width: 960px) {
 
  .aim__img {
    display: block;
  }
}

@media only screen and (max-width: 960px) {
  .aim-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .aim__subtitle,
  .aim__title {
    padding: 0 1rem;
    text-align: left;
    align-items: left;
  }

  .aim__row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .aim__text {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
  }

  .aim__description {
    /* padding: 0 9rem; */
    padding: 0 1rem;
    text-align: left;
  }

  .aim__btn-container {
    display: flex;
    padding: 0 1rem;
    justify-content: left;
    width: 100%;
  }
  
}


.aim__image {
  /* margin-left: 6rem; */
  max-width: 500px;
  border-radius: 0.8rem;
  margin-right: auto;
  padding-right: 0px;
  /* aspect-ratio: 4/5; */
}

.mobile-aim__image {
  margin-block: 2rem;
  margin-inline: auto;
  border-radius: 0.8rem;
  aspect-ratio: 5/6;
  max-width: 500px;
  margin-inline: auto;
}

@media only screen and (min-width: 960px) {
  .aim-section{
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .mobile-aim__image {
    display: none;
  }
}

/* Partner Section Styles (same as in HeroSection) */
.partners-card {
  position: absolute;
  bottom: -4rem;
  left: 2.5vw;
  background-color: #fff;
  height: 8rem;
  border-radius: 0.75rem;
  box-shadow: 0px 19px 32px 0px rgba(0, 0, 0, 0.1);
  width: 95vw;
  display: none;
}

@media only screen and (min-width: 830px) {

  .partners-card {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-width: 1024px) {
  .partners-card {
    width: 80vw;
    left: 10vw;
  }
}

.partners-card .title__section {
  border-right: 1px solid var(--gray-2, #ccc);
  display: none;
}

@media only screen and (min-width: 1024px) {
  .partners-card .title__section {
    display: grid;
    align-content: center;
  }
}

.partners__title {
  color: var(--gray-1, #777);
  font-size: 1.5rem;
}

.partners__logo {
  max-height: 3rem;
  filter: saturate(0%) contrast(15%);
  transition: filter 300ms cubic-bezier(0.595, 0.06, 0.03, 0.835);
  cursor: pointer;
  margin: 0 2.5rem;
}

.partners__logo:hover {
  filter: saturate(100%) contrast(100%);
}

.logo__section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 2rem;
}

/* Mobile Partners (Swiper) */
.partner-card--mobile {
  position: absolute;
  z-index: 999;
  bottom: -4rem;
  background-color: #fff;
  left: 8vw;
  right: 8vw;
  height: 9rem;
  border-radius: 0.75rem;
  box-shadow: 0px 19px 32px 0px rgba(0, 0, 0, 0.1);
  width: 84vw;
  display: none;
}

@media only screen and (max-width: 830px) {
  
  .partner-card--mobile {
    display: block;
  }
  .aim-section{
    text-align: left;
    align-items: left;
    justify-content: left;
  }
}

.mobile-partner__title {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  text-align: center;
}

</style>