import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import router from "./router";
import 'vue3-carousel/dist/carousel.css'
import VueGtag from "vue-gtag";

loadFonts();

createApp(App)
  .use(router)
  .use(vuetify)
  .use(VueGtag, { config: { id: process.env.VUE_APP_GA_MEASUREMENT_ID } })
  .mount("#app");
