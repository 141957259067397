<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
  name: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: false,
  },
  company: {
    type: String,
    required: false,
  },
  message: {
    type: String,
    required: false,
  },
  hasImage: {
    type: Boolean,
    required: true,
    default: false,
  },
  logoName: {
    type: String,
    required: false,
    default: '',
  }
});

const imageNameFormatter = (name) => {
  const name_ = name.split(' ').join('_').toLowerCase();
  return name_;
};

const customerName = computed(() => {
  return props.hasImage
    ? require(`@/assets/img/${imageNameFormatter(props.name)}.webp`)
    : `https://api.dicebear.com/8.x/initials/svg?seed=${props.name}`;
});

const companyLogo = computed(() => {
  try {
    return props.logoName 
      ? require(`@/assets/img/${props.logoName}.svg`)
      : null;
  } catch (e) {
    console.warn(`Logo not found: ${props.logoName}`);
    return null;
  }
});
</script>

<template>
  <div class="client-card">
    <v-col>
    <!-- <v-col  class="d-flex "> -->
      <div class="client-card__header">
        <div class="profile-section">
          <img :src="customerName" class="client-image" height="75" />
          <div class="profile-details">
            <p class="client-name">{{ props.name || '' }}</p>
            <p class="client-title">{{ props.title }} <br> {{ props.company }}</p>
          </div>
        </div>
        <div class="logo-section" v-if="companyLogo">
          <img :src="companyLogo" :alt="`${props.company} Logo`" class="company-logo" />
        </div>
      </div>
    </v-col>
    
    <v-col>
      <div class="client-card__message ">
        <p class="message-text">{{ props.message || '' }}</p>
        
      </div>
    </v-col>
    <div class="text-right">
        <v-icon class="quote-icon">mdi-format-quote-close</v-icon>
    </div>
    
    <!-- <div class="client-card__footer">
      <a href="#" class="read-more-link">Read customer story →</a>
    </div> -->
  </div>
</template>

<style scoped>
.client-card {
 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  background-color: var(--white-1);
  width: 100%;
  max-width: 450px;  
  gap: 1rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
  min-height: 450px;
 margin-left: 2rem
}

.client-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-section {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.client-image {
  border-radius: 40px;
  box-shadow: 0px 0px 16px var(--shadow-1);
  object-fit: cover;
}

.profile-details {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 0.25rem;
}

.client-name {
  font-weight: 600;
  font-size: 1rem;
  margin: 0;
  color: var(--dark-1);
}

.client-title {
  font-size: 0.9rem;
 
}

.company-logo {
  height: 40px;
}

.client-card__message {
  
  font-family: 'Lora', serif;
  font-size: 0.9rem;
  color: var(--dark-1);
  font-weight: 500;
}


.quote-icon {
  font-size: 3rem;
  color: var(--accent-red-lighten);
}

.client-card__header{
  text-align: right;
  width: 100%;
}

.client-card__footer {
  text-align: center;
  padding: 1rem;
}

.read-more-link {
  color: #00AEFF;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  transition: color 0.2s ease;
}

.read-more-link:hover {
  color:#2563EB;
}

@media (max-width: 768px) {
  .client-card {
    padding: 1.5rem;
    min-height: 590px;
    margin-left: 0;
  }
  

  
  .profile-section {
    display: grid;
    gap: 0.75rem;
  }
  
  .company-logo {
    height: 30px;
  }
}
</style>






