<template>
  <section class="section--values">
    <div class="section__info">
      <h2 class="section__title dms700">Our <span class="underline">Values</span></h2>
      <p class="section__description">
        These values form the foundation of who we are, guiding how we elevate professionals,
        empower businesses, and fuel innovation without limits.
      </p>
    </div>
    
    <div class="values-container">
      <!-- First row -->
      <div class="value-card">
        <div class="icon-container">
          <img :src="require('@/assets/img/driving.svg')" alt="Trophy icon" class="value-icon" />
        </div>
        <div class="value-content">
          <h3 class="value-heading"><span class="colored-text">Driving</span> Success</h3>
          <p class="value-description">
            We focus on empowering our customers and Nepali professionals to achieve
            their goals through meaningful collaboration.
          </p>
        </div>
      </div>
      
      <div class="value-card">
        <div class="icon-container">
          <img :src="require('@/assets/img/innovation.svg')" alt="Innovation icon" class="value-icon" />
        </div>
        <div class="value-content">
          <h3 class="value-heading"><span class="colored-text">Innovation</span> at the Core</h3>
          <p class="value-description">
            Our solutions are designed to spark creativity, enable breakthroughs, and
            build a better future for businesses and communities.
          </p>
        </div>
      </div>
      
      <!-- Second row -->
      <div class="value-card">
        <div class="icon-container">
          <img :src="require('@/assets/img/growth.svg')" alt="Growth icon" class="value-icon" />
        </div>
        <div class="value-content">
          <h3 class="value-heading"><span class="colored-text">Growth</span> for All</h3>
          <p class="value-description">
            We enable sustainable growth for our clients while creating meaningful
            impact in Nepal
          </p>
        </div>
      </div>
      
      <div class="value-card">
        <div class="icon-container">
          <img :src="require('@/assets/img/unlimited.svg')" alt="Unlimited possibilities icon" class="value-icon" />
        </div>
        <div class="value-content">
          <h3 class="value-heading"><span class="colored-text">Unlimited</span> Possibilities</h3>
          <p class="value-description">
            We are committed to unlocking potential, delivering exceptional results, and
            creating lasting impact without boundaries.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "GoalSection",
};
</script>

<style scoped>
.section--values {
  padding: 1rem 1rem;
  background-color: var(--secondary-color-lightest);
  position: relative;
  overflow: hidden;
  width: 100%;
}

.section__info {
  margin-top: 4rem;
  text-align: left;
  max-width: 100%;
  
  /* margin: 0 auto 3rem; */
}

.section__title {
  font-size: 2.5rem;
  padding-left: 2rem;
  color: var(--dark-color);
  position: relative;
  display: inline-block;
 
}

.underline {
    position: relative;
    display: inline-block;
  }
  
  .underline::after {
     content: "";
    position: absolute;
    left: 50%;
    bottom: -5px;
    width: 100%;
    height: 10px;
    background-image: url("@/assets/img/underline.svg"); /* Using alias */
    background-repeat: no-repeat;
    background-size: contain;
    transform: translateX(-50%);
  }
/* .section__title::after {
  content: '';
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 4px;
  background-color: var(--accent-red);
  border-radius: 2px;
} */

.section__description {
  font-size: 16px;
  /* line-height: 1.6; */
  padding: 0 2rem;

  width: 100%;
  margin: 1.5rem auto 0;
  color: var(--dark-color);
  margin-bottom: 2rem;
  text-align: left;
}

.values-container {
  display: grid;
  gap: 2rem;
  max-width: 100%;
  margin: 3rem 2rem;
}

.value-card {
  display: grid;
  flex-direction: column;
  max-width: 100%;

  align-items: left;
  text-align: left;
  /* padding: 1.5rem; */
}

.icon-container {
 
  /* display: flex; */
  align-items: left;
  justify-content: left;
}

.value-icon {
  width: 40px;
  height: auto;
}

.value-content {
  width: 100%;
  text-align: left;
}

.value-heading {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  /* font-weight: 600; */
  color: var(--dark-color);
}

.colored-text {
  color: var(--primary-color);
}

.value-description {
  font-size: 1rem;
  line-height: 1.5;
  color: var(--dark-color);

}

/* Responsive styles */
@media (min-width: 768px) {
  .section--values {
    /* padding: 6rem 2rem; */
    padding: 10px;
    /* margin: 10px; */
  }
  
  .values-container {
    grid-template-columns: repeat(2, 1fr);
    text-align: left;
    align-items: flex-start;
    
  }
  
  .value-card {
    flex-direction: row;
    text-align: left;
    align-items: flex-start;
    
  }
  
  .icon-container {
    margin-right: 1.5rem;
    margin-bottom: 0;
  }
  
  .value-content {
    text-align: left;
  }
}

@media (min-width: 992px) {
  
  
  .section__title {
    font-size: 3rem;
  }
  
  .value-card {
    padding: 2rem;
    text-align: left;
    align-items: flex-start;
  }
  
  .value-heading {
    font-size: 1.75rem;
  }
  
  .value-description {
    font-size: 1.1rem;
  }
}

@media (min-width: 1200px) {
  
  
  .value-heading {
    font-size: 2rem;
  }
  
  .value-card {
    
    padding: 2.5rem;
    text-align: left;
    align-items: flex-start;
  }
  .values-container {
    align-items: center;
    justify-content: center;
    display: grid;
    gap: 2rem;
    max-width: 100%;
    padding:0 12%;
    margin: 0rem 2rem;
  }
  .section__info {
    margin-top: 4rem;
    text-align: center;
    max-width: 100%;
    padding: 0 5%;
    /* margin: 0 auto 3rem; */
  }
  .section__description {    
    padding-left: 10%;
    text-align: center;

    /* width: 80%; */
    
  }
 
}


</style>