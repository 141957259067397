<template>
  <div class="job-openings">
    <hero-section :title="title" :showWhiteWave="showWhiteWave" />
    <div class="section__container">
      <div class="loader" v-if="UIController.loader.contentLoading">
        <v-progress-circular indeterminate></v-progress-circular>
        <p class="mt-5">Hold On! while we fetch some details.</p>
      </div>
      <div v-else>
        <v-card
          elevation="0"
          class="error-card"
          v-if="
            UIController.displayController.hasQueryVariable &&
            !applicationData.role
          "
        >
          <v-card-title class="error-card__title text-left dms700"
            >This Job Opening is Not Available</v-card-title
          >
          <v-card-text>
            <h3 class="error-card__text-title text-left m500">
              Hmm! Looks like the job opening you are trying to access does not
              exist.
            </h3>
            <p class="error-card__text text-left m500">
              You can always look through other job openings available right
              now.
            </p>
          </v-card-text>
        </v-card>
        <div v-else class="form__container">
          <form class="job-application" method="POST" ref="applicationForm" @submit.prevent="validateForm">
            <p class="form-title text-left" v-if="applicationData.role">
              Application for {{ applicationData.role }}
            </p>

            <v-text-field
              label="Full Name *"
              name="name"
              aria-label="Name"
              class="input-field m500 mb-3"
              variant="outlined"
              rounded
              v-model="applicationData.name"
              :error="applicationError.name"
              :error-messages="applicationErrorMessage.name"
              aria-required="true"
            ></v-text-field>
            <v-text-field
              label="Email *"
              name="email"
              aria-label="Email"
              class="input-field m500 mb-3"
              variant="outlined"
              v-model="applicationData.email"
              :error="applicationError.email"
              :error-messages="applicationErrorMessage.email"
              aria-required="true"
              type="email"
              hint="example@mail.com"
            ></v-text-field>
            <v-text-field
              label="Phone Number *"
              aria-label="Phone Number"
              class="input-field m500 mb-3"
              variant="outlined"
              name="phone"
              prefix="+977"
              v-model="applicationData.phone"
              :error="applicationError.phone"
              :error-messages="applicationErrorMessage.phone"
              aria-required="true"
              hint="With Country Code, +9779801234567"
            ></v-text-field>
            <v-text-field
              label="Location"
              aria-label="Location"
              class="input-field m500 mb-3"
              variant="outlined"
              name="location"
              v-model="applicationData.location"
              :error="applicationError.location"
              :error-messages="applicationErrorMessage.location"
              aria-required="true"
              hint="Your current city, province"
            ></v-text-field>
            <v-text-field
              label="GitHub Username"
              aria-label="GitHub Username"
              class="input-field m500 mb-3"
              variant="outlined"
              name="github"
              prefix="github.com/"
              v-model="applicationData.github"
              :error="applicationError.github"
              :error-messages="applicationErrorMessage.github"
              aria-required="true"
              hint="github.com/username"
            ></v-text-field>
            <v-text-field
              label="Linkedin Username *"
              aria-label="Linkedin Username"
              class="input-field m500 mb-3"
              variant="outlined"
              name="linkedin"
              prefix="linkedin.com/in/"
              v-model="applicationData.linkedin"
              :error="applicationError.linkedin"
              :error-messages="applicationErrorMessage.linkedin"
              aria-required="true"
              hint="linkedin.com/in/username"
            ></v-text-field>
            <v-autocomplete
              label="Languages"
              multiple
              chips
              aria-label="Languages"
              class="input-field m500 mb-3"
              variant="outlined"
              :items="languages"
              name="languages"
              item-title="label"
              item-value="id"
              v-model="applicationData.languages"
              :error="applicationError.languages"
              :error-messages="applicationErrorMessage.languages"
            ></v-autocomplete>
            <v-autocomplete
              label="Frameworks"
              multiple
              chips
              aria-label="Frameworks"
              class="input-field m500 mb-3"
              variant="outlined"
              :items="frameworks"
              name="frameworks"
              item-title="label"
              item-value="id"
              v-model="applicationData.frameworks"
              :error="applicationError.frameworks"
              :error-messages="applicationErrorMessage.frameworks"
            ></v-autocomplete>
            <v-autocomplete
              label="Softwares"
              multiple
              chips
              aria-label="Softwares"
              class="input-field m500 mb-3"
              variant="outlined"
              :items="softwares"
              name="softwares"
              item-title="label"
              item-value="id"
              v-model="applicationData.softwares"
              :error="applicationError.softwares"
              :error-messages="applicationErrorMessage.softwares"
            ></v-autocomplete>
            <v-textarea
              label="Message to the Hiring Manager"
              aria-label="Message to the Hiring Manager"
              class="input-field m500 mb-3"
              variant="outlined"
              name="message"
              v-model="applicationData.message"
              :error="applicationError.message"
              :error-messages="applicationErrorMessage.message"
              aria-required="true"
              no-resize
            ></v-textarea>
            <v-file-input
              label="Resume *"
              aria-label="Resume"
              class="input-field m500 mb-3"
              variant="outlined"
              name="cv"
              chips
              accept="application/pdf"
              
              persistent-hint
              v-on:change="onFileChange"
              v-model="applicationData.cv"
              :error="applicationError.cv"
              :error-messages="applicationErrorMessage.cv"
              aria-required="true"
            ></v-file-input>
            <hcaptcha
              classList="my-4 text-left"
              @verify="onVerify"
              @expired="onExpired"
              @closed="onClosed"
              @reset="onReset"
            />
            <p class="text-left">
              <v-btn
                type="submit"
                class="form-btn"
                size="large"
                elevation="0"
                :loading="submitting"
                :disabled="submitting"
                >{{ submitting ? 'Submitting...' : 'Submit' }}</v-btn
              >
            </p>
          </form>
        </div>
      </div>
    </div>
    <v-snackbar
      color="red-darken-2"
      v-model="UIController.snackbars.captchaSnackBar"
      >Please Verify The Captcha</v-snackbar
    >
  </div>
</template>

<script>
import HeroSectionVue from '@/components/partials/HeroSection.vue';
import jobOpeningService from '@/services/API/jobOpeningService';
import utilService from '@/services/API/utilService';
import applicationService from '@/services/API/applicationService';
import hcaptchaWidget from '@/components/partials/hcaptchaWidget.vue';

export default {
  name: 'JobApplication',
  components: {
    'hero-section': HeroSectionVue,
    hcaptcha: hcaptchaWidget,
  },
  data: () => ({
    title: 'Drop Your Application',
    submitting: false,
    showWhiteWave: true,
    applicationData: {
      role: null,
      name: null,
      email: null,
      phone: null,
      location: null,
      github: null,
      linkedin: null,
      languages: [],
      frameworks: [],
      softwares: [],
      message: null,
      cv: [], // Initialize as an array
      verified: false,
    },
    applicationError: {
      name: false,
      email: false,
      phone: false,
      location: false,
      github: false,
      linkedin: false,
      languages: false,
      frameworks: false,
      softwares: false,
      message: false,
      cv: false,
      verified: false,
    },
    applicationErrorMessage: {
      name: new Array(),
      email: new Array(),
      phone: new Array(),
      location: new Array(),
      github: new Array(),
      linkedin: new Array(),
      languages: new Array(),
      frameworks: new Array(),
      softwares: new Array(),
      message: new Array(),
      cv: new Array(),
    },
    languages: [],
    frameworks: [],
    softwares: [],
    UIController: {
      loader: {
        contentLoading: true,
      },
      displayController: {
        hasQueryVariable: false,
      },
      snackbars: {
        captchaSnackBar: false,
        successSnackBar: false,
        errorSnackBar: false,
      },
    },
  }),
  methods: {
    onFileChange(event) {
      // Convert FileList to an array of File objects for Vuetify
      this.applicationData.cv = Array.from(event.target.files);
      console.log('File Selected:', this.applicationData.cv);
    },
    onVerify() {
      this.applicationData.verified = true;
    },
    onExpired() {
      this.applicationData.verified = false;
    },
    onClosed() {
      this.applicationData.verified = false;
    },
    onReset() {
      this.applicationData.verified = false;
    },
    async submitForm() {
      this.submitting = true;
      if (this.applicationData.github !== '') {
        this.applicationData.github = `github.com/${this.applicationData.github}`;
      }
      this.applicationData.linkedin = `linkedin.com/in/${this.applicationData.linkedin}`;

      await applicationService
        .createApplication(this.applicationData)
        .then(() => {
          this.$router.back();
        })
        .catch(() => {
          window.location.reload();
        });
      this.submitting = false;
    },
    validateForm() {
      Object.keys(this.applicationError).forEach(key => {
        this.applicationError[key] = false;
        if (this.applicationErrorMessage[key]) {
          this.applicationErrorMessage[key] = [];
        }
      });

      let hasErrors = false;

      if (!this.applicationData.name) {
        this.applicationError.name = true;
        this.applicationErrorMessage.name.push('This Field Is Required');
        hasErrors = true;
      }
      
      if (!this.applicationData.email) {
        this.applicationError.email = true;
        this.applicationErrorMessage.email.push('This Field Is Required');
        hasErrors = true;
      }
      
      if (!this.applicationData.phone) {
        this.applicationError.phone = true;
        this.applicationErrorMessage.phone.push('This Field Is Required');
        hasErrors = true;
      }
      
      if (!this.applicationData.github) {
        this.applicationData.github = "";
      }
      
      if (!this.applicationData.linkedin) {
        this.applicationError.linkedin = true;
        this.applicationErrorMessage.linkedin.push('This Field Is Required');
        hasErrors = true;
      }
      if (!this.applicationData.cv || this.applicationData.cv.length === 0) {
        this.applicationError.cv = true;
        this.applicationErrorMessage.cv.push('This Field Is Required');
        hasErrors = true;
      }
      if (!this.applicationData.verified) {
        this.applicationError.verified = true;
        this.UIController.snackbars.captchaSnackBar = true;
        hasErrors = true;
      }

      if (!hasErrors) {
        this.submitForm();
      }
    },
  },
  async created() {
    document.getElementById('loaderSection').classList.add('d-none');
    this.UIController.loader.contentLoading = true;
    if (this.$route.query.jobId) {
      this.UIController.displayController.hasQueryVariable = true;
      await jobOpeningService
        .showJobOpenings(this.$route.query.jobId)
        .then((response) => {
          console.log(response.data.name);
          if (response.status === 200) {
            this.applicationData.role = response.data.name;
          }
        });
    }
    const utilResponse = await utilService
      .getLanguageFrameworkUtil()
      .then((response) => {
        return response;
      });

    this.languages = utilResponse.data.fields.filter(
      (field) => field.name === 'Languages'
    )[0].type_config.options;

    this.frameworks = utilResponse.data.fields.filter(
      (field) => field.name === 'Frameworks'
    )[0].type_config.options;

    this.softwares = utilResponse.data.fields.filter(
      (field) => field.name === 'Softwares'
    )[0].type_config.options;

    this.UIController.loader.contentLoading = false;
  },
};
</script>

<style scoped>
.error-card {
  background-color: var(--light-1);
  max-width: 500px;
  margin-inline: auto;
  margin-block: 2rem;
}

.loader {
  padding-block: 5rem;
}

.form-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.form__container {
  margin-block: 5rem;
}

.job-application {
  max-width: 900px;
  margin-inline: auto;
}

.form-btn {
  letter-spacing: 0px;
  text-transform: capitalize;
  background-color: var(--primary-color-lighten);
  color: var(--light-1);
  margin-block: 1rem;
}
</style>   

