<script setup>
import { reactive, ref, onMounted, onBeforeUnmount } from 'vue';
import DeveloperStories from '@/views/whySmartworks/forDevelopers/Sections/DeveloperStories.vue';
import ClientStories from '@/views/whySmartworks/forHiringCompanies/Sections/ClientStories.vue';

const current = ref('Customer');
const progressValue = ref(0);
let progressInterval = null;
const isAutoProgressEnabled = ref(true);

const UIController = reactive({
  showingClients: true,
  showingDevelopers: false,
});

const show = (type) => {
  current.value = type;
  UIController.showingClients = type === 'Customer';
  UIController.showingDevelopers = type === 'Talent';
  
  // Stop auto progression on manual click
  stopAutomaticProgression();
  
  // Reset progress for the active tab
  if (type === 'Customer') {
    progressValue.value = 0;
  } else {
    progressValue.value = 50;
  }
  
  isAutoProgressEnabled.value = false;
};

const startAutomaticProgression = () => {
  if (!isAutoProgressEnabled.value) return;
  
  // Progress every 50ms until reaching 100%
  progressInterval = setInterval(() => {
    if (progressValue.value < 100) {
      progressValue.value += 0.3;
      
      // When halfway through, switch to talent testimonials
      if (progressValue.value >= 50 && !UIController.showingDevelopers) {
        UIController.showingClients = false;
        UIController.showingDevelopers = true;
        current.value = 'Talent';
      }
    } else {
      // Reset to beginning when complete
      progressValue.value = 0;
      UIController.showingClients = true;
      UIController.showingDevelopers = false;
      current.value = 'Customer';
    }
  }, 50);
};

const stopAutomaticProgression = () => {
  if (progressInterval) {
    clearInterval(progressInterval);
    progressInterval = null;
  }
};

const handleDocumentClick = (event) => {
  const tabs = document.querySelectorAll('.tab');
  const clickedOutsideTabs = !Array.from(tabs).some(tab => tab.contains(event.target));
  
  if (clickedOutsideTabs && !isAutoProgressEnabled.value) {
    isAutoProgressEnabled.value = true;
    startAutomaticProgression();
  }
};

onMounted(() => {
  startAutomaticProgression();
  document.addEventListener('click', handleDocumentClick);
});

onBeforeUnmount(() => {
  stopAutomaticProgression();
  document.removeEventListener('click', handleDocumentClick);
});
</script>

<template>
  <section class="stories">
    <div class="testimonial-header">
      <h1 class="testimonial-title">Our <span class="underline">Testimonial</span> </h1>
    </div>
    
    <div class="testimonial-tabs">
      <div class="tab-container">
        <div 
          class="tab customer-tab"
          :class="{ active: UIController.showingClients }"
          @click="show('Customer')"
        >
          <span>Customer Testimonial</span>
          <div 
            class="tab-progress" 
            v-if="UIController.showingClients" 
            :style="{
              width: isAutoProgressEnabled && progressValue <= 50 ? (progressValue * 2) + '%' : (UIController.showingClients ? '100%' : '0%')
            }"
          ></div>
        </div>
        
        <div 
          class="tab talent-tab"
          :class="{ active: UIController.showingDevelopers }"
          @click="show('Talent')"
        >
          <span>Talent Testimonial</span>
          <div 
            class="tab-progress" 
            v-if="UIController.showingDevelopers" 
            :style="{
              width: isAutoProgressEnabled && progressValue > 50 ? ((progressValue - 50) * 2) + '%' : (UIController.showingDevelopers ? '100%' : '0%')
            }"
          ></div>
        </div>
      </div>
    </div>
    
    <div class="testimonial-content">
      <ClientStories v-if="UIController.showingClients" />
      <DeveloperStories v-if="UIController.showingDevelopers" />
    </div>
  </section>
</template>

<style scoped>
.stories {
  padding-block: 2rem 4rem;
  background-color: var(--light-1);
  transition: all 200ms ease-in-out;
}

@media only screen and (min-width: 1028px) {
  .stories {
    padding-block: 4rem;
  }
}
.underline {
  position: relative;
  display: inline-block;
}

.underline::after {
   content: "";
  position: absolute;
  left: 50%;
  bottom: -5px;
  width: 100%;
  height: 10px;
  background-image: url("@/assets/img/underline.svg"); /* Using alias */
  background-repeat: no-repeat;
  /* background-size: contain; */
  transform: translateX(-50%);
}
.testimonial-header {
  text-align: center;
  margin-bottom: 2rem;
}

.testimonial-title {
  /* font-size: 56px; */
  font-size: clamp(32px, 5vw, 56px) !important;

  font-weight: 700;
}

.testimonial-tabs {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.tab-container {
  display: flex;
  width: 80%;
  max-width: 100%;
  background-color: #f5f9fc;
  border-radius: 8px 8px 0 0;
}

.tab {
  flex: 1;
  padding: 1rem;
  font-size: 1.3rem;
  text-align: center;
  position: relative;
  cursor: pointer;
  transition: all 1s ease;
}
@media only screen and (max-width: 616px){
  .tab{
    font-size: 1rem;
  }
}

.customer-tab {
  /* background-color: rgba(255, 107, 0, 0.05); */
  color: #777;
  border-bottom: 1px solid rgb(204, 196, 196);
}

.customer-tab.active {
  background-color: rgba(255, 107, 0, 0.1);
  color: #ff6b00;
}

.talent-tab {
  /* background-color: rgba(0, 149, 217, 0.05); */
  color: #777;
  border-bottom: 1px solid rgb(204, 196, 196);

}

.talent-tab.active {
  background-color: rgba(0, 149, 217, 0.1);

  color: #0095d9;
}

.tab-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  transition: width 0.3s ease;
}

.customer-tab .tab-progress {
  background-color: #ff6b00;
}

.talent-tab .tab-progress {
  background-color: #0095d9;
}

.testimonial-content {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
}
</style>