<template>
  <div class="page">
    <div class="container">
      <!-- Sticky Sidebar -->
      <div class="sidebar">
        <ServiceSidebar />
      </div>

      <!-- Main Content -->
      <div class="content">
        <!-- Hero Section -->
        <section class="hero">
          <div class="hero-content">
            <h2>
              Design My App: <br /><span class="highlight"
                >Expert UX/UI Design Services</span
              >
            </h2>

            <p>
              Transform your app with user-focused design solutions that enhance engagement and streamline user experiences. At DIGU, we craft user-friendly, impactful UX/UI designs that reflect your brand identity and cater to your audience’s needs.
            </p>
            <!-- <div class="hero-image">
              <img
                :src="require('@/assets/img/designApp-hero.svg')"
                alt="UX/UI Design Services"
              />
            </div> -->
            <!-- Video Section -->
            <div class="hero-video">
              <v-dialog
                v-model="displayVideo"
                fullscreen
                :scrim="false"
                class="video-dialog"
              >
                <v-btn
                  icon
                  style="position: absolute; z-index: 10; top: 10px; right: 10px"
                  @click="displayVideo = !displayVideo"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <video controls autoplay loop muted playsinline id="video-background" ref="dialogVideoPlayer">
                  <source
                    :src="isMobile ? require('@/assets/video/DesignMyApp-mobile.mp4') : require('@/assets/video/DesignMyApp.mp4')"
                    type="video/mp4"
                  />
                </video>
              </v-dialog>

              <!-- Background Video -->
              <video autoplay loop muted playsinline id="video-background">
                <source
                  :src="isMobile ? require('@/assets/video/DesignMyApp.mp4') : require('@/assets/video/DesignMyApp.mp4')"
                  type="video/mp4"
                />
              </video>

              <!-- Watch Now Button -->
              <div class="video-overlay">
                <v-btn
                  size="large"
                  prepend-icon="mdi-play-circle-outline"
                  class="watch-now-btn"
                  @click="playVideo"
                  elevation="0"
                >
                  Watch Now
                </v-btn>
              </div>
            </div>
          </div>
        </section>

        <!-- UX/UI Design Process -->
        <section class="process">
          <div class="process-content">
            <h2>
              Comprehensive <br /><span class="highlight"
                >UX/UI Design Process</span
              >
            </h2>
            <h2></h2>
            <p>
              We follow a holistic process to create exceptional app interfaces:
            </p>

            <div class="timeline" ref="timeline">
              <div
                v-for="(step, index) in steps"
                :key="index"
                class="step"
                :class="{ active: step.isActive }"
              >
                <div class="timeline-line">
                  <div class="circle" :class="{ active: step.isActive }">
                    {{ index + 1 }}
                  </div>
                  <div
                    v-if="index < steps.length - 1"
                    class="line"
                    :style="{
                      '--progress': `${step.lineProgress}%`,
                      '--opacity': 1 - step.lineProgress / 100,
                    }"
                  >
                    <div class="solid-line"></div>
                    <div class="dotted-line"></div>
                  </div>
                </div>

                <div class="step-content" :class="{ active: step.isActive }">
                  <div class="content-text">
                    <h4>{{ step.title }}</h4>
                    <p>{{ step.description }}</p>
                  </div>
                  <div class="content-image">
                    <img :src="step.image" :alt="step.title" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- Design Services section -->
        <section class="service">
          <div class="service-content">
            <h2>
              {{ designServices.title }} <br /><span class="highlight">{{
                designServices.subtitle
              }}</span>
            </h2>

            <p>{{ designServices.description }}</p>
            <div class="service-cards">
              <div
                v-for="(card, index) in designServices.cards"
                :key="index"
                class="service-card"
              >
                <img :src="card.icon" :alt="card.title" />
                <h4>{{ card.title }}</h4>
                <p>{{ card.description }}</p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div class="service-list">
            <MobileServiceList />
          </div>
        </section>

        <!-- Get in Touch Section -->
        <section>
          <div>
            <ScheduleNow dynamicText="and let’s bring your vision to life!" />
          </div>
        </section>

        
          
      
      </div>

      
    </div>
  </div>
  
</template>

<script>
import ServiceSidebar from '../components/ServiceSidebar.vue';
import ScheduleNow from '../components/GetInTouch.vue';
import MobileServiceList from '../components/MobileServiceList.vue';

export default {
  name: 'DesignMyApp',
  components: {
    ServiceSidebar,
    ScheduleNow,
    MobileServiceList,
  },
  data() {
    return {
      displayVideo: false,
      isMobile: false,
      designServices: {
        title: "Why Choose DIGU's",
        subtitle: 'UX/UI Design Services?',
        description:
          "Partner with us to create interfaces that are as functional as they are beautiful. Here's why businesses trust DIGU:",
        cards: [
          {
            icon: require('@/assets/img/user-center.svg'),
            title: 'User-Centered Designs',
            description:
              'We prioritize user behavior and preferences to deliver tailored solutions.',
          },
          {
            icon: require('@/assets/img/brand-consistency.svg'),
            title: 'Brand Consistency',
            description:
              'Every design reflects and reinforces your brand identity.',
          },
          {
            icon: require('@/assets/img/enhanced-engagement.svg'),
            title: 'Enhanced Engagement',
            description:
              'Our interfaces promote interaction and boost user retention.',
          },
          {
            icon: require('@/assets/img/scalable-solution.svg'),
            title: 'Scalable Solutions',
            description: "Designed to grow with your app's evolving needs.",
          },
        ],
      },
      steps: [
        {
          title: 'User Behavior Analysis',
          description:
            'Understand how users interact with your app to define solutions that meet their needs.',
          image: require('@/assets/img/step1.svg'),
          isActive: false,
          lineProgress: 0,
        },
        {
          title: 'Wireframe Development',
          description:
            'Lay the groundwork for seamless navigation and functional layouts.',
          image: require('@/assets/img/step2.svg'),
          isActive: false,
          lineProgress: 0,
        },
        {
          title: 'High-Fidelity Designs',
          description:
            'Produce polished, visually appealing designs that optimize usability and aesthetics.',
          image: require('@/assets/img/step3.svg'),
          isActive: false,
          lineProgress: 0,
        },
      ],
    };
  },

 

  methods: {
    handleScroll() {
      if (!this.$refs.timeline) return;

      const timelineElements = this.$refs.timeline.querySelectorAll('.step');
      const windowHeight = window.innerHeight;

      timelineElements.forEach((element, index) => {
        const rect = element.getBoundingClientRect();
        const stepProgress = (windowHeight - rect.top) / (windowHeight + rect.height);

        // Update step active state
        this.steps[index].isActive = stepProgress > 0.3;

        // Update line progress for all steps
        let progress = Math.max(0, Math.min(100, stepProgress * 100));

        // Ensure the previous line completes its progression while in the viewport
        if (index > 0) {
          const prevRect = timelineElements[index - 1].getBoundingClientRect();

          // If the previous step is still in the viewport, ensure its line completes
          if (prevRect.top >= 0 && prevRect.bottom <= windowHeight) {
            this.steps[index - 1].lineProgress = 100; // Force completion
          } else if (prevRect.bottom < 0) {
            // If the previous step has scrolled past the viewport, ensure its line is complete
            this.steps[index].lineProgress = 100;
          } else {
            // Otherwise, don't start the next line
            progress = 0;
          }
        }

        // Update the current step's line progress
        this.steps[index].lineProgress = progress;
      });
    },
    playVideo() {
      this.displayVideo = true;
    },
    pauseVideo() {
      console.log('Pause Video');
    },
    checkScreenSize() {
      // Update isMobile based on screen width
      const wasAlreadyMobile = this.isMobile;
      this.isMobile = window.innerWidth <= 780;
      
      // If mobile state changed, update video sources
      if (wasAlreadyMobile !== this.isMobile) {
        this.$nextTick(() => {
          // Force video elements to reload with new source
          if (this.$refs.backgroundVideoPlayer) {
            const videoElement = this.$refs.backgroundVideoPlayer;
            videoElement.load();
            videoElement.play().catch(e => console.log('Auto-play prevented:', e));
          }
          
          if (this.displayVideo && this.$refs.dialogVideoPlayer) {
            const dialogVideo = this.$refs.dialogVideoPlayer;
            dialogVideo.load();
            dialogVideo.play().catch(e => console.log('Dialog video play prevented:', e));
          }
        });
      }
    },
  },

  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);

    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
    window.removeEventListener('scroll', this.handleScroll);

  },
 
  created() {
    document.getElementById('loaderSection').classList.add('d-none');
  },
};
</script>

<style scoped>
.page {
  background-color: #f7f8fc;
}
section{
  width: 100%;
  margin-left: 2%;
}
.container {
  display: flex;
  max-width: 1450px;
  margin: 0rem auto;
  padding: 0 2rem 4rem;
  font-family: Arial, sans-serif;
}

.sidebar {
  flex: 0 0 350px;
  position: -webkit-sticky;
  position: sticky;
  top: 150px;
  margin-top: 2rem;
  margin-left: 3%;
  z-index: 999;
}
.sidebarAsList{
  display: none;
}
.content {
  display: grid;
  flex-grow: 1;
  text-align: left;
}

h2 {
  font-size: clamp(28px, 5vw, 44px);
  margin-bottom: 20px;
}

.highlight {
  color: #ff4d4d;
}
.hero{
  margin-bottom: 40px;
}
.hero-content {
  padding: 1rem 5rem;
  padding-top: 3rem;
}

.hero p {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Hero Video Section */
.hero-video {
  position: relative;
  height: 400px; /* Default height for larger screens */
  overflow: hidden;
}

#video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire container */
}

.video-overlay {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 2; /* Ensure the button is above the video */
}

.watch-now-btn {
  background-color: var(--accent-red);
  color: #fff;
  text-transform: capitalize;
  letter-spacing: 0px;
  font-family: 'Outfit Medium', sans-serif;
  font-weight: 500;
}


.hero-image img {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.process {
  margin-top: 40px;
  margin-bottom: 40px;
}

.process-content {
  max-width: 1200px;
  margin: 0 auto;
  margin: 0rem 5rem;
  padding: 2rem 2rem;
  background-color: #e5f6ff;
}

.timeline {
  position: relative;
  margin-top: 3rem;
}

.step {
  display: flex;
  gap: 2rem;
  margin-bottom: 4rem;
}

/* Timeline Line */
.timeline-line {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 40px;
}

.circle {
  width: 40px;  /* Fixed width */
  height: 40px; /* Fixed height */
  border-radius: 50%; /* Changed from 70% to 50% for perfect circle */
  border: 2px solid #e0e0e0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #999;
  transition: all 0.3s ease;
  font-size: 1rem;
  min-width: 40px; /* Added to prevent shrinking */
  min-height: 40px; /* Added to prevent shrinking */
}

.circle.active {
  background: #007bff;
  border-color: #007bff;
  color: white;
}

.line {
  position: relative;
  width: 2px;
  height: 100%;
  margin-top: 10px;
  background: linear-gradient(to bottom, #999 50%, transparent 50%);
  background-size: 2px 8px;
}

.solid-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--progress);
  background: #007bff;
  transition: height 2.3s ease;
}

.dotted-line {
  position: absolute;
  top: var(--progress);
  left: 0;
  width: 100%;
  height: calc(100% - var(--progress));
  background: linear-gradient(to bottom, #999 50%, transparent 50%);
  background-size: 2px 8px;
  opacity: var(--opacity);
}

.step-content {
  flex: 1;
  display: flex;
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  opacity: 0.7;
  transform: translateY(20px);
  transition: all 0.3s ease;
}

.step-content.active {
  opacity: 1;
  transform: translateY(0);
}

.content-text {
  flex: 1;
  padding-right: 2rem;
}

.content-text h4 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;
}

.content-text p {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
}

.content-image {
  width: 250px;
  flex-shrink: 0;
}

.content-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}



.service {
  margin-top: 40px;
}

.service-content {
  margin: 1rem 5rem;
}

.service p {
  font-size: 16px;
  color: #666;
  margin-bottom: 40px;
  line-height: 1.6;
}

.service-cards {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;
}

.service-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
  max-width: 300px;
  transition: transform 0.3s ease-in-out;
}

.service-card:hover {
  transform: translateY(-10px);
}

.service-card img {
  width: 50px;
  margin-bottom: 20px;
}

.service-card h4 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.service-card p {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}
/* responsiveness */
@media (max-width: 1290px) {
  .step-content {
    flex-direction: column;
  }

  .content-text {
    padding-right: 0;
    padding-bottom: 1rem;
  }

  .content-image {
    width: 100%;
  }
}

@media (max-width: 930px) {
  .sidebar {
    display: none;
  }
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .content{
    padding: 5px;
  }
  .hero-content,
  .process-content,
  .service-content {
    margin: 0;
    
    padding-left: 1rem;
    padding-right: 1rem;
    
  }
  .circle {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }

 

  .step-content {
    flex-direction: column;
    padding: 1rem;
    max-width: 600px;
   
  }

  .content-text {
    padding-right: 0;
    padding-bottom: 1rem;
  }

  .content-image {
    align-items: center;
    width: 100%;
    
  }
  section{
    width: 100%;
    margin: 0;
    padding: 0;
  }

}

@media (min-width: 931px) {
  .service-list {
    display: none;
  }
}

@media (max-width: 930px) {
  .service-list {
    display: block;
  }
}
/* Add responsiveness */
@media (max-width: 768px) {
  .content{
    padding: 10px;
  }
  .hero-content,
  .process-content,
  .service-content {
    /* margin: 0; */
    
    padding-left: 1rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    
    
  }
   .container{
    padding: 0;
   }
   .hero-video {
    height: 50vh; /* Use viewport height for better responsiveness */
    position: relative;
    overflow: hidden;
  }

  #video-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .video-overlay {
    bottom: 10px;
    left: 10px;
  }

  .watch-now-btn {
    font-size: 14px;
    padding: 8px 16px;
  }
  .sidebarAsList{
    display:run-in;
  }
}
video {
  /* Ensure all videos maintain aspect ratio */
  max-width: 100%;
  height: auto;
  /* iOS specific fix */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
</style>
