<template>
 <div class="page">
   <div class="container">
    <!-- Sidebar -->
    <div class="sidebar">
      <ServiceSidebar />
    </div>

    <!-- Main Content -->
    <div class="content">
      <!-- Hero Section -->
      <section class="hero">
        <div class="hero-content">
          <h2>Build My App: <br><span class="highlight">MVP Development Services For Startups</span></h2>
          <p>
            Bring your app idea to life with DIGU’s MVP Development Services, designed to help startups and entrepreneurs launch confidently. From strategic consulting to development, we deliver scalable Minimum Viable Products (MVPs) that streamline your path to market and get you there faster.
          </p>
          <!-- image section -->
          <!-- <div class="hero-image">
            <img
              :src="require('@/assets/img/mvp-dev-service.svg')"
              alt="MVP Development Services"
            />
          </div> -->
          <div class="hero-video">
            <v-dialog
              v-model="displayVideo"
              fullscreen
              :scrim="false"
              class="video-dialog"
            >
              <v-btn
                icon
                style="position: absolute; z-index: 10; top: 10px; right: 10px"
                @click="displayVideo = !displayVideo"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <video controls autoplay loop muted playsinline id="video-background" ref="dialogVideoPlayer">
                <source
                  :src="isMobile ? require('@/assets/video/BuildMyApp-mobile.mp4') : require('@/assets/video/BuildMyApp.mp4')"
                  type="video/mp4"
                />
              </video>
            </v-dialog>

            <!-- Background Video -->
            <video autoplay loop muted playsinline id="video-background">
              <source
                :src="isMobile ? require('@/assets/video/BuildMyApp-mobile.mp4') : require('@/assets/video/BuildMyApp.mp4')"
                type="video/mp4"
              />
            </video>

            <!-- Watch Now Button -->
            <div class="video-overlay">
              <v-btn
                size="large"
                prepend-icon="mdi-play-circle-outline"
                class="watch-now-btn"
                @click="playVideo"
                elevation="0"
              >
                Watch Now
              </v-btn>
            </div>
          </div>
        </div>

        <!-- wave container -->
        <div class="wave-container">
          <div class="wave-content">
            <h2 >What Is An <span class="highlight">MVP?</span></h2>
            <p class="description">
              A Minimum Viable Product (MVP) is a simplified version of your app that includes only the essential features to validate your concept and engage early adopters.
            </p>
          </div>
        </div>
      </section>

      

      <!-- Features Section -->
      <section class="features">
        <div class="features-content">
          <h2>Why Build An MVP First? <br><span class="highlight">Key Benefits Of MVP Development</span></h2>
          <p>
            Creating an MVP offers several key advantages:
          </p>
          <div class="feature-cards">
            <div v-for="(feature, index) in mvpFeatures" :key="index" class="feature-card">
              <img :src="feature.icon" :alt="feature.title" />
              <h4>{{ feature.title }}</h4>
              <p>{{ feature.description }}</p>
            </div>
          </div>
          <h4 class="summary">An MVP sets the foundation for success by enabling faster testing, improvement, and validation of your concept.</h4>
        </div>
      </section>


      <!-- MVP service section -->
      <section class="mvp-services">
        <div class="services-content">
            <h2>Our MVP Development <span class="highlight">Services</span> </h2>
            <div class="content-container">
                <div class="sub-content">
                  <div class="text">
                    <h4>MVP Consulting</h4>
                    <p>
                      Gain strategic insights on essential features and how to meet market needs.
                    </p>
                  </div>
                  <div>
                    <img
                      :src="require('@/assets/img/mvp-development-img1.svg')"
                    />
                  </div>
                </div>
                <div class="sub-content">
                  <div>
                    <img
                      :src="require('@/assets/img/mvp-development-img2.svg')"
                    />
                  </div>
                  <div class="text">
                    <h4>MVP Development</h4>
                    <p>
                      We build scalable MVPs that help you validate ideas, attract early users, and position your app for future growth.
                    </p>
                  </div>
                  

                </div>
            </div>
        </div>

      </section >

      <section>
          <div class="service-list">
            <MobileServiceList />
          </div>
        </section>
      <!-- Get in Touch Section -->
      <section>
        <div>
          <ScheduleNow dynamicText="to start your MVP journey!" />
        </div>
      </section>
      
          
      
      </div>

      
    </div>
  </div>
  
</template>

<script>
import ServiceSidebar from '../components/ServiceSidebar.vue';
import ScheduleNow from '../components/GetInTouch.vue';
import MobileServiceList from '../components/MobileServiceList.vue';

export default {
  name: 'DesignMyApp',
  components: {
    ServiceSidebar,
    ScheduleNow,
    MobileServiceList,
  },
  data() {
    return {
      displayVideo: false,
      isMobile: false,
      mvpFeatures: [
        {
          icon: require('@/assets/img/cost-effective.svg'),
          title: 'Cost-Effective',
          description:
            'Save time and resources by focusing on core features.',
        },
        {
          icon: require('@/assets/img/market-testing.svg'),
          title: 'Market Testing',
          description:
            'Validate your idea with real users and gather valuable insights.',
        },
        {
          icon: require('@/assets/img/user-feedback.svg'),
          title: 'User Feedback',
          description:
            'Identify what works and what does not to refine your product.',
        },
        {
          icon: require('@/assets/img/risk-reduction.svg'),
          title: 'Risk Reduction',
          description:
            'Detect and address issues early to avoid costly mistakes.',
        },
        {
            icon: require('@/assets/img/focused-development.svg'),
            title: 'Focused Development',
            description: 'Build a product that solves the main problem effectively.'
        }
      ],
    };
  },
  methods: {
    playVideo() {
      this.displayVideo = true;
    },
    pauseVideo() {
      console.log('Pause Video');
    },
    checkScreenSize() {
      // Update isMobile based on screen width
      this.isMobile = window.innerWidth <= 780;
    },
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  created() {
    document.getElementById('loaderSection').classList.add('d-none');
  },
};
</script>

<style scoped>

.page{
  background-color: #F7F8FC;

}
section{
  width: 100%;
  margin-left: 2%;
}
.container {
  display: flex;
  max-width: 1450px;
  /* min-height: 100vh; */
  margin: 0rem auto;
  padding: 0 2rem 4rem;
  font-family: Arial, sans-serif;
}

/* Sidebar */
.sidebar {

  flex: 0 0 350px;
  position: -webkit-sticky;
  position: sticky;
  top: 150px;
  margin-top: 2rem;
  margin-left: 3%;
  z-index: 999;

}

@media (min-width: 931px) {
  .service-list {
    display: none;
  }
}

@media (max-width: 930px) {
  .service-list {
    display: block;
  }
}

/* Main Content */
.content {
  display: grid;
  flex-grow: 1;
  text-align: left;
}

h2 {
font-size: clamp(28px, 5vw, 44px);
margin-bottom: 20px;
}

.highlight{
  color: #ff4d4d;
}

/* Hero Section */

.hero-content {
  padding:1rem 5rem;
  padding-top: 3rem;
}

.hero p {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;
}

.hero-image img {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Hero Video Section */
.hero-video {
  position: relative;
  height: 400px; /* Default height for larger screens */
  overflow: hidden;
}

#video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire container */
}

.video-overlay {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 2; /* Ensure the button is above the video */
}

.watch-now-btn {
  background-color: var(--accent-red);
  color: #fff;
  text-transform: capitalize;
  letter-spacing: 0px;
  font-family: 'Outfit Medium', sans-serif;
  font-weight: 500;
}


/* wave container */
.wave-container {
  position: relative;
  width: 83%; /* Full width */
  margin: 0; /* Remove margin */
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 40 500 300"><path d="M0,100 C50,50 150,150 300,100 C450,50 550,150 800,100 L800,300 L0,300 Z" fill="%23A3DEFF"/></svg>');
  background-repeat: no-repeat;
  background-size: cover; 
 
  margin-left: 5rem;
  margin-right: 2rem;
  padding-top: 9rem; /* Add padding for the content */
  padding-bottom: 1rem;

}

.wave-content {
  
  
  padding:1rem 5rem;
 
  width: 100%;
  
}

.title {
  font-size: 2.5rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 1.5rem;
}

.highlight {
  color: #FF6B6B;
  font-weight: 600;
}

.description {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555;
  max-width: 600px;
}





/* Features Section */

.features-content {
  padding: 6rem 0;
  margin: 0rem 5rem;
}


.summary{
  margin-top: 2rem;
}

.feature-cards {
  margin: 1rem 0;
  padding: 1rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;

}

.feature-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
  max-width: 300px;
  transition: transform 0.3s ease-in-out;
}

.feature-card:hover {
  transform: translateY(-10px);
}

.feature-card img {
  width: 50px;
  margin-bottom: 20px;
}

.feature-card h4 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.feature-card p {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}

/* mvp development service */
.mvp-services{
  padding: 1rem 1.5rem;
  padding-top: 2rem;
  width: 85%;
  background-color: #e5f6ff;
  margin-left: 5rem;

  
}
.sub-content{
  margin: 1.5rem 0;
  max-width: 700px;
  display: flex;
  justify-content: flex-start;
  padding: auto;
  text-align: left;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  /* padding: 20px; */
  text-align: left;

  transition: transform 0.3s ease-in-out;
  
}
.sub-content:hover{
  transform: translateY(-10px);
}
.sub-content img{
  max-width: 350px;
  padding: 5px;
}
.text{
  padding: 3rem 2rem;
  text-align: left;
  
}
@media (max-width: 1190px) {
  .wave-container {
    /* margin: 0; */
    width: 75%;
    height: 500px;
  }
  section{
    margin: 0;
  }
  .text{
    margin: 1rem;
    padding: 1rem;
  }
  .sub-content img{
    max-width: 200px;
    margin: 1rem;
  }
}
@media (max-width:1060px){
/* wave container */
  .wave-content {
    
    
    padding:1rem 2rem;
  
    width: 100%;
    
  }
  .sub-content {
    flex-direction: column; /* Stack content vertically on smaller screens */
    align-items: left; /* Center align items */
    text-align: left; /* Center text */
  }
  .sub-content img {
    max-width: 100%; /* Make images responsive */
    margin: 0rem;
    padding: 1rem;
    margin-bottom: 0rem; /* Add spacing between image and text */
  }
  .text{
    padding: 0 1rem;
    text-align: left;
    
  }
}

@media (max-width: 930px){
  .sidebar{
    display: none;
  }
  section{
    margin: 0;
  }
  .container {
    padding-left: 1rem;
      padding-right: 1rem;
  }
  .content{
    padding: 5px;
  }

  .hero-content,
  .features-content,
  .mvp-services,
  .wave-content{
    margin: 0;
    padding: 0;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
   
  }
  
  .mvp-services{
    width: 100%;
    padding-top: 2rem;
    margin-top: 40px;

  }
  .wave-container {
    width: 100%;
    margin: 0; /* Remove margin for smaller screens */
    height: auto;
  }
    
  .sub-content {
    width: 100%;
    flex-direction: column; /* Stack content vertically on smaller screens */
    align-items: center; /* Center align items */
    text-align: center; /* Center text */
  }

  .sub-content img {
    max-width: 100%; /* Make images responsive */
    margin: 1rem;
    padding: 0;
  }

  .text {
    margin-left: 0; /* Remove left margin for smaller screens */

  }

  .features-content{
    margin: 1rem;
    
  }
}

/* Add responsiveness */
@media (max-width: 768px) {
  .wave-container {
    padding-bottom: 4rem;
  }
  
  .title {
    font-size: 2rem;
  }
  
  .description {
    font-size: 1.1rem;
  }
  .features-content{
    padding: 0;
  }
  .hero-video {
    height: 50vh; /* Use viewport height for better responsiveness */
    position: relative;
    overflow: hidden;
  }

  #video-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .video-overlay {
    bottom: 10px;
    left: 10px;
  }

  .watch-now-btn {
    font-size: 14px;
    padding: 8px 16px;
  }
}
@media (max-width: 578px){
  .sub-content img {
    max-width: 100%; /* Make images responsive */
    margin: 0;
    padding: 1rem;
  }
}
video {
  /* Ensure all videos maintain aspect ratio */
  max-width: 100%;
  height: auto;
  /* iOS specific fix */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
</style>
