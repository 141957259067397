<template>
  <section class="why-section">
    <div class="section__container">
      <div class="grid-container">
        
        <div class="image__wrapper">
          <img :src="require('@/assets/img/understanding.svg')" alt="DIGU illustration">
        </div>
        <div class="content__wrapper">
          <p class="o600 why__subtitle section__subtitle-2">Understanding <span class="underline">DIGU</span></p>
        </div>
        <div class="description__wrapper">
          <p class="m500 why__description section__description-2">
            DIGU stands for 'Driving Innovation and Growth Unlimited' and is more than just an acronym; it carries profound significance in Nepali (Newari) culture. The concepts of clan, descent, and generation symbolize who we are, where we come from, and our commitment to supporting Nepal's future. Rooted in rich heritage, DIGU reflects the flourishing of talent, much like a lotus blooming from humble beginnings to global success. Our name embodies our dedication to creating a supportive and empowering environment for exceptional local talent.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UnderstandingDigu',
};
</script>

<style scoped>
.why-section {
  padding-block: 4rem 5rem;
  background: #CFECFC;
}

.section__container {
  max-width: 1200px;
  margin: 0 auto;
  /* padding: 0 1rem; */
}

.grid-container {
  display: grid;
  gap: 1rem;
}

.image__wrapper img {
  max-width: 500px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.why__subtitle {
  color: #17171F;
  font-size: clamp(32px, 5vw, 56px) !important;
  text-align: left;
}

.why__description {
  margin-top: 0;
  text-align: left;
  color: var(--dark-2);
  width: 100%;
}

.underline {
  position: relative;
  display: inline-block;
}

.underline::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -5px;
  width: 100%;
  height: 10px;
  background-image: url("@/assets/img/underline.svg");
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateX(-50%);
}
@media (min-width: 1200px) {
  .why__description {
    margin-top: -1rem;
    
  }

}
@media (min-width: 960px) {
  .grid-container {
    grid-template-columns: 1fr 0.9fr; 
    grid-template-areas: 
      "title image"
      "description image";
    align-items: center;
  }

  .content__wrapper {
    grid-area: title;
    /* padding-right: 2rem; */
  }

  .image__wrapper {

    grid-area: image;
    
  }

  .description__wrapper {

    grid-area: description;
    padding-right: 2rem;
  }
}

@media (max-width: 959px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
  
  .why__subtitle,
  .why__description {
    text-align: left;
  }
  
  .image__wrapper {
    order: 1;
    /* margin: 2rem 0; */
  }
  
  .description__wrapper {
    order: 2;
  }
}
/*   */
</style>