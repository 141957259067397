<template>
  <section class="get-in-touch">
    <h2>Get in touch with us!</h2>
    <button class="schedule-button" @click="redirectToCalendly">
      Schedule now
    </button>
    <p>{{ dynamicText }}</p> <!-- Dynamic paragraph text -->
  </section>
</template>

<script>
export default {
  name: "GetInTouch",
  props: {
    dynamicText: {
      type: String,
      required: true, // Ensure the prop is passed
    },
  },
  methods: {
    redirectToCalendly() {
      window.open(
        "https://calendly.com/digu/meeting-with-sunbid-nepali-time",
        "_blank"
      );
    },
  },
};
</script>

<style scoped>
.get-in-touch {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
  padding: 40px 20px;
  /* background: linear-gradient(to right, #eef4fd, #ffffff); */
}

.get-in-touch h2 {
  font-size: 28px;
  font-weight: bold;
  color: #000;
  margin-bottom: 20px;
}

.schedule-button {
  background-color: #003d64; /* Navy blue color */
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.schedule-button:hover {
  background-color: #002b4a; /* Slightly darker navy blue */
  transform: scale(1.05);
}

.get-in-touch p {
  font-size: 16px;
  color: #003d64;
  margin: 0;
}
</style>
