<template>
  <section class="hero-section">
    <div class="section__container">
      <div class="hero-content">
        <h1 class="hero-title">
          The Professional Services Company That
          <span class="underline">Builds</span> Your App And
          <span class="underline">Scales</span> Your Team
        </h1>

        <p class="hero-description">
          DIGU delivers scalable, cost-effective solutions to help you build apps and grow your engineering team. Our services enhance productivity, drive innovation, and support business growth. We empower startups and enterprises to achieve their goals while driving meaningful impact in the lives of people in Nepal.
        </p>
      </div>

      <div class="solutions-section">
        <div class="solutions-header">
          <h2 class="solutions-title">Our Solutions</h2>
          <div class="navigation-controls">
            <button 
              class="nav-arrow" 
              @click="previousSlide" 
              :disabled="currentSlide === 0"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </button>
            <button 
              class="nav-arrow" 
              @click="nextSlide" 
              :disabled="currentSlide >= maxSlides"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </button>
          </div>
        </div>

        <div 
          class="solutions-slider" 
          :style="{ width: `${sliderWidth}px` }"
          @touchstart="onTouchStart"
          @touchmove="onTouchMove"
          @touchend="onTouchEnd"
        >
          <div class="solutions-track" :style="sliderStyle">
            <div 
              v-for="(solution, index) in solutions" 
              :key="index" 
              class="solution-card"
              :style="{ width: `${cardWidth}px` }"
            >
              <h3 class="card-title">{{ solution.title }}</h3>
              <p class="card-description">{{ solution.description }}</p>
              <a :href="solution.link" class="read-more">
                Read more
                <v-icon size="small">mdi-arrow-right</v-icon>
              </a>
            </div>
          </div>
        </div>

        <div class="slider-indicators">
          <div 
            v-for="(_, index) in solutions" 
            :key="index" 
            class="indicator"
            :class="{ 
              'active': index >= currentSlide && index < currentSlide + visibleCards,
              'current': index === currentSlide
            }"
          ></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';

const currentSlide = ref(0);
const cardWidth = ref(380); // Default card width
const gapWidth = 24;
const visibleCards = ref(3); // Default visible cards
const sliderWidth = ref(0); // Width of the slider container

// Touch event variables
const touchStartX = ref(0);
const touchEndX = ref(0);
const isSwiping = ref(false);

const solutions = ref([
  {
    title: 'Design My App',
    description: "User-friendly UX/UI design for seamless experiences.",
    link: '/design-my-app'
  },
  {
    title: 'Build My App',
    description: "Launch faster with a scalable, market-ready MVP.",
    link: '/build-my-app'
  },
  {
    title: 'Review My App',
    description: "Optimize performance, security, and scalability.",
    link: '/review-my-app'
  },
  {
    title: 'Roadmap My App',
    description: "Plan your app’s future with a strategic roadmap.",
    link: '/roadmap-my-app'
  },
  {
    title: 'Staff Augmentation',
    description: "Scale your team with top tech talent on demand.",
    link: '/staff-augmentation'
  },
  {
    title: 'Identity Management for My App',
    description: "Secure, scalable identity solutions powered by Okta.",
    link: '/implement-identity-management'
  }
]);

// Calculate slider position
const sliderStyle = computed(() => ({
  transform: `translateX(${-currentSlide.value * (cardWidth.value + gapWidth)}px)`
}));

// Calculate max slides based on visible cards
const maxSlides = computed(() => {
  return Math.max(0, solutions.value.length - visibleCards.value);
});

// Update slider width and visible cards based on screen size
const updateSliderDimensions = () => {
  const screenWidth = window.innerWidth;
  const containerPadding = 48; // 24px padding on each side
  
  if (screenWidth <= 450) {
    // Very small mobile: Make card significantly smaller to fit screen
    visibleCards.value = 1;
    // Account for container padding and leave some extra margin
    cardWidth.value = screenWidth - containerPadding - 20; 
    sliderWidth.value = cardWidth.value;
  } else if (screenWidth <= 800) {
    // Standard mobile: Show exactly 1 card
    visibleCards.value = 1;
    cardWidth.value = Math.min(380, screenWidth - containerPadding);
    sliderWidth.value = cardWidth.value;
  } else if (screenWidth <= 1200) {
    // Tablet: Show exactly 2 cards
    visibleCards.value = 2;
    cardWidth.value = Math.min(350, (screenWidth - 96) / 2);
    sliderWidth.value = (cardWidth.value * 2) + gapWidth;
  } else {
    // Desktop: Show exactly 3 cards
    visibleCards.value = 3;
    cardWidth.value = 380;
    sliderWidth.value = (cardWidth.value * 3) + (gapWidth * 2);
  }
  
  // Ensure current slide is valid after resizing
  if (currentSlide.value > maxSlides.value) {
    currentSlide.value = maxSlides.value;
  }
};

// Navigation functions
const nextSlide = () => {
  if (currentSlide.value < maxSlides.value) {
    currentSlide.value++;
  }
};

const previousSlide = () => {
  if (currentSlide.value > 0) {
    currentSlide.value--;
  }
};

// Touch event handlers
const onTouchStart = (event) => {
  touchStartX.value = event.touches[0].clientX;
  isSwiping.value = true;
};

const onTouchMove = (event) => {
  if (!isSwiping.value) return;
  touchEndX.value = event.touches[0].clientX;
};

const onTouchEnd = () => {
  if (!isSwiping.value) return;

  const deltaX = touchEndX.value - touchStartX.value;
  const swipeThreshold = 50; // Minimum swipe distance to trigger slide change

  if (deltaX > swipeThreshold) {
    previousSlide();
  } else if (deltaX < -swipeThreshold) {
    nextSlide();
  }

  // Reset touch values
  touchStartX.value = 0;
  touchEndX.value = 0;
  isSwiping.value = false;
};

onMounted(() => {
  // Initialize dimensions
  updateSliderDimensions();
  
  // Add resize listener with debouncing
  let resizeTimer;
  window.addEventListener('resize', () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      updateSliderDimensions();
    }, 250);
  });
});
</script>

<style scoped>
/* Your existing styles remain unchanged */
.hero-section {
  position: relative;
  background: linear-gradient(120deg, #fbe9e7 0%, #e3f2fd 100%);
  padding: 6rem 0;
}

.section__container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
}

.hero-content {
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}

.hero-title {
  font-size: 3.5rem;
  line-height: 1.2;
  margin-bottom: 2rem;
  color: #1a1a1a;
}

.title-line {
  display: block;
  margin-top: 0.5rem;
}

.underline {
  position: relative;
  display: inline-block;
}

.underline::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -5px;
  width: 80%;
  height: 10px;
  background-image: url("@/assets/img/underline.svg");
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateX(-50%);
}

.hero-description {
  margin: 0 10%;
  font-size: 1.125rem;
  line-height: 1.6;
  color: #4a4a4a;
  margin-bottom: 4rem;
}

.solutions-section {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.solutions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  width: 90%;
}

.solutions-title {
  font-size: 2rem;
  font-weight: 600;
  color: #1a1a1a;
}

.navigation-controls {
  display: flex;
  gap: 1rem;
}

.nav-arrow {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.nav-arrow:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.solutions-slider {
  overflow: hidden;
  margin: 0 auto;
}

.solutions-track {
  display: flex;
  gap: 24px;
  transition: transform 0.3s ease-out;
}

.solution-card {
  flex-shrink: 0;
  height: 160px;
  background: white;
  border-radius: 16px;
  padding: 1rem;
  box-shadow: 0 4px 16px rgba(0,0,0,0.08);
}

.card-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 1rem;
  text-align: left;
}

.card-description {
  font-size: 1rem;
  line-height: 1.5;
  color: #4a4a4a;
  margin-bottom: 0.5rem;
  text-align: left;
}

.read-more {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #2196F3;
  text-decoration: none;
  font-weight: 500;
}

.slider-indicators {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 1.5rem;
}

.indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #E0E0E0;
  transition: all 0.3s ease;
}

.indicator.active {
  background: #2196F3;
}

.indicator.current {
  width: 24px;
  border-radius: 4px;
}

@media (max-width: 1200px) {
  .hero-title {
    font-size: 2.5rem;
  }
  
  .hero-description {
    margin: 0 5% 3rem;
  }
  .solutions-title {
    font-size: 1.8rem;
  }
}

@media (max-width: 800px) {
  .hero-title {
    font-size: 2rem;
  }
  
  .hero-description {
    font-size: 1rem;
    margin: 0 0 3rem;
  }
  
  .section__container {
    padding: 0 1.5rem;
  }
  
  .solutions-title {
    font-size: 1.6rem;
  }
}

/* Ensure the slider container never overflows */
.solutions-section {
  max-width: 100%;
  overflow: hidden;
}

/* Make sure cards scale properly and don't overflow */
.solution-card {
  flex-shrink: 0;
  height: auto;
  min-height: 160px;
  max-width: 100%;
  background: white;
  border-radius: 16px;
  padding: 1rem;
  box-shadow: 0 4px 16px rgba(0,0,0,0.08);
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/* Additional mobile-specific adjustments */
@media (max-width: 450px) {
  .section__container {
    padding: 0 12px;
  }
  
  .card-title {
    font-size: 1.1rem;
  }
  
  .card-description {
    font-size: 0.9rem;
  }
  
  .solution-card {
    padding: 0.75rem;
  }
  .solutions-title {
    font-size: 1.2rem;
  }
}
</style>