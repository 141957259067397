<script setup>
import { reactive, ref, onMounted, onBeforeUnmount } from 'vue';

const current = ref('Professional');
const progressValue = ref(0);
let progressInterval = null;
const isAutoProgressEnabled = ref(true);
const isInViewport = ref(false);

const UIController = reactive({
  showingProfessionals: true,
  showingStartups: false,
});

const show = (type) => {
  current.value = type;
  UIController.showingProfessionals = type === 'Professional';
  UIController.showingStartups = type === 'Startup';
  
  // Stop auto progression on manual click
  stopAutomaticProgression();
  
  // Reset progress for the active tab
  if (type === 'Professional') {
    progressValue.value = 0;
  } else {
    progressValue.value = 50;
  }
  
  isAutoProgressEnabled.value = false;
};

const startAutomaticProgression = () => {
  if (!isAutoProgressEnabled.value || !isInViewport.value) return;
  
  // First clear any existing intervals to prevent duplicates
  stopAutomaticProgression();
  
  // Progress every 50ms until reaching 100%
  progressInterval = setInterval(() => {
    if (progressValue.value < 100) {
      progressValue.value += 0.3;
      
      // When halfway through, switch to talent testimonials
      if (progressValue.value >= 50 && !UIController.showingStartups) {
        UIController.showingProfessionals = false;
        UIController.showingStartups = true;
        current.value = 'Startup';
      }
    } else {
      // Reset to beginning when complete
      progressValue.value = 0;
      UIController.showingProfessionals = true;
      UIController.showingStartups = false;
      current.value = 'Professional';
    }
  }, 50);
};

const stopAutomaticProgression = () => {
  if (progressInterval) {
    clearInterval(progressInterval);
    progressInterval = null;
  }
};

const handleDocumentClick = (event) => {
  const tabs = document.querySelectorAll('.tab');
  const clickedOutsideTabs = !Array.from(tabs).some(tab => tab.contains(event.target));
  
  if (clickedOutsideTabs && !isAutoProgressEnabled.value) {
    isAutoProgressEnabled.value = true;
    checkVisibilityAndStartProgression();
  }
};

// Function to check if an element is in the viewport
const isElementInViewport = (el) => {
  if (!el) return false;
  
  const rect = el.getBoundingClientRect();
  
  return (
    rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.bottom >= 0 &&
    rect.left <= (window.innerWidth || document.documentElement.clientWidth) &&
    rect.right >= 0
  );
};

// Function to check tab visibility and start/stop progression accordingly
const checkVisibilityAndStartProgression = () => {
  const tabContainer = document.querySelector('.testimonial-tabs');
  
  if (tabContainer) {
    isInViewport.value = isElementInViewport(tabContainer);
    
    if (isInViewport.value && isAutoProgressEnabled.value) {
      startAutomaticProgression();
    } else {
      stopAutomaticProgression();
    }
  }
};

// Debounce function to limit the frequency of scroll event handling
const debounce = (func, delay) => {
  let timeoutId;
  return function(...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

// Create a debounced version of our visibility check function
const debouncedCheckVisibility = debounce(checkVisibilityAndStartProgression, 100);

onMounted(() => {
  // Initial check when component is mounted
  setTimeout(checkVisibilityAndStartProgression, 300);
  
  // Add event listeners
  document.addEventListener('click', handleDocumentClick);
  window.addEventListener('scroll', debouncedCheckVisibility);
  window.addEventListener('resize', debouncedCheckVisibility);
  
  // Set up an interval to periodically check visibility
  // This helps with cases where the element becomes visible due to other DOM changes
  const visibilityCheckInterval = setInterval(checkVisibilityAndStartProgression, 1000);
  
  onBeforeUnmount(() => {
    stopAutomaticProgression();
    document.removeEventListener('click', handleDocumentClick);
    window.removeEventListener('scroll', debouncedCheckVisibility);
    window.removeEventListener('resize', debouncedCheckVisibility);
    clearInterval(visibilityCheckInterval);
  });
});
</script>

<template>
  <section class="stories">
    <div class="testimonial-header">
      <h1 class="testimonial-title">Our <span class="underline">Story</span></h1>
      <p>DIGU was born from a mission to solve real challenges faced by Nepali professionals and startups worldwide</p>
    </div>
    
    <div class="testimonial-tabs">
      <div class="tab-container">
        <div 
          class="tab customer-tab"
          :class="{ active: UIController.showingProfessionals }"
          @click="show('Professional')"
        >
          <span>Opportunities For Nepali Professionals</span>
          <div 
            class="tab-progress" 
            v-if="UIController.showingProfessionals" 
            :style="{
              width: isAutoProgressEnabled && progressValue <= 50 ? (progressValue * 2) + '%' : (UIController.showingProfessionals ? '100%' : '0%')
            }"
          ></div>
        </div>
        
        <div 
          class="tab talent-tab"
          :class="{ active: UIController.showingStartups }"
          @click="show('Startup')"
        >
          <span>Solutions For Startups</span>
          <div 
            class="tab-progress" 
            v-if="UIController.showingStartups" 
            :style="{
              width: isAutoProgressEnabled && progressValue > 50 ? ((progressValue - 50) * 2) + '%' : (UIController.showingStartups ? '100%' : '0%')
            }"
          ></div>
        </div>
      </div>
    </div>
    
    <div class="testimonial-content">
      <!-- Professionals Content -->
      <div v-if="UIController.showingProfessionals" class="content-section">
        <div class="content-container">
          <div class="text-content">
            <!-- <h2 class="section-title">Opportunities For Nepali Professionals</h2> -->
            <p class="section-description">
              We saw a growing number of Nepali professionals in the U.S. struggling to find stable, well-paying jobs. Many live far from their families, sacrificing precious time with loved ones just to make ends meet. Back home in Nepal, it's a different but equally painful story: young talent leaving the country in search of better opportunities, leaving behind a nation that needs its builders.
            </p>
            <p class="section-description">
              We knew there had to be a better way. What if Nepali professionals could thrive in their careers without leaving their country—or their families?
            </p>
          </div>
          <div class="image-content">
            <img :src="require('@/assets/img/digu-professionals.jpg')" alt="Nepali professionals collaborating" class="content-image" />
          </div>
        </div>
      </div>
      
      <!-- Startups Content -->
      <div v-if="UIController.showingStartups" class="content-section">
        <div class="content-container">
          <div class="text-content">
            <!-- <h2 class="section-title">Solutions For Startups</h2> -->
            <p class="section-description">
              Startups in the U.S. often face challenges with the high costs of hiring software engineers and building their development teams. Finding the right talent is tough, and for many businesses, staying on budget while scaling feels nearly impossible.
            </p>
            <p class="section-description">
              That's where DIGU comes in. We connect startups with talented Nepali professionals, giving businesses access to world-class engineers at affordable rates. Startups can build apps, scale their teams, and grow efficiently, while Nepali professionals get the chance to advance their careers without leaving their families behind. It's a solution that benefits everyone.
            </p>
          </div>
          <div class="image-content">
            <img :src="require('@/assets/img/digu-startups.jpeg')" alt="Startup solution concept" class="content-image" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.stories {
  padding-block: 2rem 4rem;
  background-color: var(--light-1);
  transition: all 200ms ease-in-out;
}

@media only screen and (min-width: 1028px) {
  .stories {
    padding-block: 4rem;
  }
}

.underline {
  position: relative;
  display: inline-block;
}

.underline::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -5px;
  width: 100%;
  height: 10px;
  background-image: url("@/assets/img/underline.svg");
  background-repeat: no-repeat;
  transform: translateX(-50%);
}

.testimonial-header {
  text-align: center;
  margin-bottom: 2rem;
  padding: 0 2rem;
}

.testimonial-title {
  font-size: clamp(32px, 5vw, 56px) !important;
  font-weight: 700;
  margin-bottom: 1rem;
}

.testimonial-tabs {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.tab-container {
  display: flex;
  width: 80%;
  max-width: 100%;
  background-color: #f5f9fc;
  border-radius: 8px 8px 0 0;
}

.tab {
  flex: 1;
  padding: 1rem;
  font-size: 1.3rem;
  text-align: center;
  position: relative;
  cursor: pointer;
  transition: all 1s ease;
}

@media only screen and (max-width: 616px) {
  .tab {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 592px) {
  .tab {
    font-size: 0.8rem;
    font-weight: bold;
  }
}

.customer-tab {
  color: #777;
  border-bottom: 1px solid rgb(204, 196, 196);
}

.customer-tab.active {
  background-color: rgba(255, 107, 0, 0.1);
  color: #ff6b00;
}

.talent-tab {
  color: #777;
  border-bottom: 1px solid rgb(204, 196, 196);
}

.talent-tab.active {
  background-color: rgba(0, 149, 217, 0.1);
  color: #0095d9;
}

.tab-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  transition: width 0.3s ease;
}

.customer-tab .tab-progress {
  background-color: #ff6b00;
}

.talent-tab .tab-progress {
  background-color: #0095d9;
}

.testimonial-content {
  width: 80%;
  max-width: 1400px;
  margin: 0 auto;
}
.text-content{
  text-align: left;
}

/* Content section styles */
.content-section {
  margin-top: 20px;
}

.content-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media only screen and (min-width: 768px) {
  .content-container {
    flex-direction: row;
    align-items: center;
  }
  
  .text-content {
    flex: 1;
    padding-right: 20px;
    text-align: left;
  }
  
  .image-content {
    flex: 1;
    max-width: 550px;
  }
}

.section-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: #333;
}

.section-description {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 1rem;
}

.content-image {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
</style>