<template>
<div class="page">
    <div class="container ">
    <!-- Sticky Sidebar -->
    <div class="sidebar">
      <ServiceSidebar />
    </div>

    <!-- Main Content -->
    <div class="content">
      <!-- Hero Section -->
      <section class="hero">
       <div class="hero-content">
           <h2>Implement Identity Management <br> <span class="highlight">For My App</span> </h2>
      
          <h4>Powered by Okta in Partnership with Anthropic Identity</h4>
          <p>
            DIGU and Anthropic Identity have joined forces to provide world-class Okta consulting and implementation services. This partnership blends Anthropic Identity’s deep expertise in customer identity solutions with DIGU’s access to a skilled pool of certified Okta professionals in Nepal, delivering innovation with a purpose.
          </p>
          <!-- <div class="hero-image">
            <img
              :src="require('@/assets/img/identity-management-hero.svg')"
              
            />
          </div> -->
          <!-- Video Section -->
          <div class="hero-video">
            <v-dialog
              v-model="displayVideo"
              fullscreen
              :scrim="false"
              class="video-dialog"
            >
              <v-btn
                icon
                style="position: absolute; z-index: 10; top: 10px; right: 10px"
                @click="displayVideo = !displayVideo"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <video controls autoplay loop muted playsinline id="video-background" ref="dialogVideoPlayer">
                <source
                  :src="isMobile ? require('@/assets/video/IdentityManagement-mobile.mp4') : require('@/assets/video/IdentityManagement.mp4')"
                  type="video/mp4"
                />
              </video>
            </v-dialog>

            <!-- Background Video -->
            <video autoplay loop muted playsinline id="video-background">
              <source
                :src="isMobile ? require('@/assets/video/IdentityManagement-mobile.mp4') : require('@/assets/video/IdentityManagement.mp4')"
                type="video/mp4"
              />
            </video>

            <!-- Watch Now Button -->
            <div class="video-overlay">
              <v-btn
                size="large"
                prepend-icon="mdi-play-circle-outline"
                class="watch-now-btn"
                @click="playVideo"
                elevation="0"
              >
                Watch Now
              </v-btn>
            </div>
          </div>
       </div>
      </section>

      <!-- UX/UI Design Process -->
      <section class="process">
        <div class="process-content">
          <h2>Comprehensive Okta <br><span class="highlight">Consulting And Implementation Services</span></h2>
          <p>
            Transform how your business manages customer identity with expert-led solutions designed for scalability, security, and seamless integration. Our services cover every stage of the process:
          </p>

          <div class="timeline" ref="timeline">
            <div 
              v-for="(step, index) in steps" 
              :key="index"
              class="step"
              :class="{ active: step.isActive }"
            >
              <div class="timeline-line">
                <div class="circle" :class="{ active: step.isActive }">
                  {{ index + 1 }}
                </div>
                <div 
                  v-if="index < steps.length - 1" 
                  class="line"
                  :style="{ 
                    '--progress': `${step.lineProgress}%`,
                    '--opacity': 1 - step.lineProgress / 100
                  }"
                >
                  <div class="solid-line"></div>
                  <div class="dotted-line"></div>
                </div>
              </div>

              <div class="step-content" :class="{ active: step.isActive }">
                <div class="content-text">
                  <h4>{{ step.title }}</h4>
                  <p>{{ step.description }}</p>
                </div>
                <div class="content-image">
                  <img :src="step.image" :alt="step.title" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Features Section -->
      <section class="features">
        <div class="features-content">
          <h2>Why Choose Anthropic Identity’s <br> <span class="highlight">Okta Services Through DIGU?</span></h2>
        
          
          <div class="feature-cards">
            <div v-for="(feature, index) in mvpFeatures" :key="index" class="feature-card">
              <img :src="feature.icon" :alt="feature.title" />
              <h4>{{ feature.title }}</h4>
              <p>{{ feature.description }}</p>
            </div>
          </div>
          
        </div>
      </section>

        <!-- frame point section -->
      <section class="implementation">
        <div class="implementation-content">
          <h2>Delivering Innovation <br> <span class="highlight">With Purpose</span> </h2>
          
          <p>
            Every Okta implementation project with Anthropic Identity and DIGU supports positive social impact in Nepal:
          </p>
          <div class="implementation-cards">
            <div
              v-for="(feature, index) in implementationFeatures"
              :key="index"
              class="implementation-card"
            >
              <div>
                <img
                  :src="feature.icon"
                  :alt="feature.title"
                />
              </div>
              <div>
                <h4>{{ feature.title }}</h4>
                <p>{{ feature.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section>
          <div class="service-list">
            <MobileServiceList />
          </div>
      </section>
      <!-- Get in Touch Section -->
      <section>
        <div>
          <ScheduleNow dynamicText="to transform your identity strategy!" />
        </div>
      </section>
      
          
      
      </div>

      
    </div>
  </div>
  
</template>

<script>
import ServiceSidebar from '../components/ServiceSidebar.vue';
import ScheduleNow from '../components/GetInTouch.vue';
import MobileServiceList from '../components/MobileServiceList.vue';

export default {
  name: 'DesignMyApp',
  components: {
    ServiceSidebar,
    ScheduleNow,
    MobileServiceList,
  },
  data() {
    return {
      displayVideo: false,
      isMobile: false,
      mvpFeatures: [
        {
          icon: require('@/assets/img/certified-professionals.svg'),
          title: 'Certified Professionals',
          description:
            'Gain access to experienced, Okta-certified experts ensuring flawless consulting and implementation.',
        },
        {
          icon: require('@/assets/img/seamless-integration.svg'),
          title: 'Seamless Integration',
          description:
            'Gain access to experienced, Okta-certified experts ensuring flawless consulting and implementation.',
        },
        {
          icon: require('@/assets/img/secure-solution.svg'),
          title: 'Scalable and Secure Solutions',
          description:
            'Build a secure foundation for your growing user base while maintaining robust security.',
        },
        
      ],
      implementationFeatures: [
        {
          icon: require('@/assets/img/job-creation.svg'),
          title: 'Job Creation',
          description: 'Create meaningful employment opportunities for skilled professionals.',
        },
        {
          icon: require('@/assets/img/educational-support.svg'),
          title: 'Educational Support',
          description: 'Fund scholarships to empower the next generation of tech talent.',
        },
        {
          icon: require('@/assets/img/skill-development.svg'),
          title: 'Skill Development',
          description: 'Provide professional development programs to advance career prospects.',
        },
      ],
      steps: [
        {
          title: 'Architecture Design and Discovery Phase',
          description: 'Analyze your requirements and design a robust identity management framework.',
          image: require('@/assets/img/identity-step1.svg'),
          isActive: false,
          lineProgress: 0
        },
        {
          title: 'Development and Implementation Phase',
          description: 'Build and integrate scalable, secure solutions tailored to your business needs.',
          image: require('@/assets/img/identity-step2.svg'),
          isActive: false,
          lineProgress: 0
        },
        {
          title: 'Production Phase',
          description: 'Ensure a seamless rollout with enterprise-grade security and scalability.',
          image: require('@/assets/img/identity-step3.svg'),
          isActive: false,
          lineProgress: 0
        },
        {
          title: 'Management Phase',
          description: 'Provide ongoing support and maintenance to optimize performance and ensure compliance.',
          image: require('@/assets/img/identity-step4.svg'),
          isActive: false,
          lineProgress: 0
        }
      ]
    };
  },

 

  methods: {
    handleScroll() {
      if (!this.$refs.timeline) return;

      const timelineElements = this.$refs.timeline.querySelectorAll('.step');
      const windowHeight = window.innerHeight;

      timelineElements.forEach((element, index) => {
        const rect = element.getBoundingClientRect();
        const stepProgress = (windowHeight - rect.top) / (windowHeight + rect.height);

        // Update step active state
        this.steps[index].isActive = stepProgress > 0.3;

        // Update line progress for all steps
        let progress = Math.max(0, Math.min(200, stepProgress * 100));

        // Ensure the previous line completes its progression while in the viewport
        if (index > 0) {
          const prevRect = timelineElements[index - 1].getBoundingClientRect();

          // If the previous step is still in the viewport, ensure its line completes
          if (prevRect.top >= 0 && prevRect.bottom <= windowHeight) {
            this.steps[index - 1].lineProgress = 100; // Force completion
          } else if (prevRect.bottom < 0) {
            // If the previous step has scrolled past the viewport, ensure its line is complete
            this.steps[index].lineProgress = 100;
          } else {
            
            progress = 0;
          }
        }

        // Update the current step's line progress
        this.steps[index].lineProgress = progress;
      });
    },
    playVideo() {
      this.displayVideo = true;
    },
    pauseVideo() {
      console.log('Pause Video');
    },
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 780;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();

    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.checkScreenSize);

  },
  created() {
    document.getElementById('loaderSection').classList.add('d-none');
  },
};
</script>


<style scoped>
.page {
  background-color: #F7F8FC;
}

section {
  width: 100%;
  margin-left: 2%;
}

.container {
  display: flex;
  max-width: 1450px;
  margin: 0rem auto;
  padding: 0 2rem 4rem;
  font-family: Arial, sans-serif;
}

/* Sidebar */
.sidebar {
  flex: 0 0 350px;
  position: sticky;
  top: 150px;
  margin-top: 2rem;
  margin-left: 3%;
  z-index: 999;
}

@media (min-width: 931px) {
  .service-list {
    display: none;
  }
}

@media (max-width: 930px) {
  .service-list {
    display: block;
  }
}

/* Main Content */
.content {
  display: grid;
  flex-grow: 1;
  text-align: left;
}

/* Hero Section */
.hero {
  margin-bottom: 40px;
}

.hero-content {
  padding: 1rem 5rem;
  padding-top: 3rem;
}

h2 {
  font-size: clamp(28px, 5vw, 44px);
  margin-bottom: 20px;
}

.highlight {
  color: #ff4d4d;
}

.hero p {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;
}

.hero-image img {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Hero Video Section */
.hero-video {
  position: relative;
  height: 400px; /* Default height for larger screens */
  overflow: hidden;
}

#video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire container */
}

.video-overlay {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 2; /* Ensure the button is above the video */
}

.watch-now-btn {
  background-color: var(--accent-red);
  color: #fff;
  text-transform: capitalize;
  letter-spacing: 0px;
  font-family: 'Outfit Medium', sans-serif;
  font-weight: 500;
}

/* Process Section */
.process {
  margin-top: 40px;
  margin-bottom: 40px;
}

.process-content {
  max-width: 1200px;
  margin: 0 auto;
  margin: 0rem 5rem;
  padding: 2rem 1.5rem;
  background-color: #e5f6ff;
}

.timeline {
  position: relative;
  margin-top: 3rem;
}

.step {
  display: flex;
  gap: 2rem;
  margin-bottom: 4rem;
}

/* Timeline Line */
.timeline-line {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 40px;
}

.circle {
  width: 40px;  /* Fixed width */
  height: 40px; /* Fixed height */
  border-radius: 50%; /* Changed from 70% to 50% for perfect circle */
  border: 2px solid #e0e0e0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #999;
  transition: all 0.3s ease;
  font-size: 1rem;
  min-width: 40px; /* Added to prevent shrinking */
  min-height: 40px; /* Added to prevent shrinking */
}


.circle.active {
  background: #007bff;
  border-color: #007bff;
  color: white;
}

.line {
  position: relative;
  width: 2px;
  height: 100%;
  margin-top: 10px;
  background: linear-gradient(to bottom, #999 50%, transparent 50%);
  background-size: 2px 8px;
}

.solid-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--progress);
  background: #007bff;
  transition: height 2.3s ease;
}

.dotted-line {
  position: absolute;
  top: var(--progress);
  left: 0;
  width: 100%;
  height: calc(100% - var(--progress));
  background: linear-gradient(to bottom, #999 50%, transparent 50%);
  background-size: 2px 8px;
  opacity: var(--opacity);
}

.step-content {
  flex: 1;
  display: flex;
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  opacity: 0.7;
  transform: translateY(20px);
  transition: all 0.3s ease;
}

.step-content.active {
  opacity: 1;
  transform: translateY(0);
}

.content-text {
  flex: 1;
  padding-right: 2rem;
}

.content-text h4 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;
}

.content-text p {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
}

.content-image {
  width: 250px;
  flex-shrink: 0;
}

.content-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1290px) {
  .step-content {
    flex-direction: column;
  }

  .content-text {
    padding-right: 0;
    padding-bottom: 1rem;
  }

  .content-image {
    width: 100%;
  }
}

/* Features Section */
.features {
  margin-top: 40px;
  margin-bottom: 40px;
}

.features-content {
  margin: 1rem 5rem;
}

.feature-cards {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;
}

.feature-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
  max-width: 300px;
  transition: transform 0.3s ease-in-out;
}

.feature-card:hover {
  transform: translateY(-10px);
}

.feature-card img {
  width: 50px;
  margin-bottom: 20px;
}

.feature-card h4 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.feature-card p {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}

/* Implementation Section */

.implementation-content {
  margin: 1rem 5rem;
}

.implementation-cards {
  margin-top: 2rem;
}

.implementation-card {
  display: flex;
  padding: 1rem;
  margin: 1rem;
  height: 120px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.implementation-card::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #9dcaf5, #5eb3da);
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: scaleX(0.9);
}

.implementation-card:hover::after {
  opacity: 1;
  transform: scaleX(1);
}

.implementation-card img {
  margin-right: 1rem;
  width: 50px;
  height: auto;
}

/* Responsive Styles */
@media (max-width: 930px) {
  .sidebar {
    display: none;
  }

  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .hero-content,
  .process-content,
  .features-content,
  .implementation-content {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 0;
    margin-right: 0;
  }

  .features{
    margin-top: 0.5rem;
  }
  
  .circle {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }

 

  .step-content {
    flex-direction: column;
    padding: 1rem;
    max-width: 600px;
   
  }

  .content-text {
    padding-right: 0;
    padding-bottom: 1rem;
  }

  .content-image {
    align-items: center;
    width: 100%;
    
  }

  

  .implementation-card {
    flex-direction: column;
    align-items: left;
    text-align: left;
    height: auto;
  }

  .implementation-card img {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  section{
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

/* Add responsiveness */
@media (max-width: 768px) {
  .wave-container {
    padding-bottom: 4rem;
  }
  
  .title {
    font-size: 2rem;
  }
  
  .description {
    font-size: 1.1rem;
  }
  .features-content{
    padding: 0;
  }
  .hero-video {
    height: 50vh; /* Use viewport height for better responsiveness */
    position: relative;
    overflow: hidden;
  }

  #video-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .video-overlay {
    bottom: 10px;
    left: 10px;
  }

  .watch-now-btn {
    font-size: 14px;
    padding: 8px 16px;
  }
}
video {
  /* Ensure all videos maintain aspect ratio */
  max-width: 100%;
  height: auto;
  /* iOS specific fix */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
</style>