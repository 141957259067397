import { createRouter, createWebHistory } from 'vue-router';

// Views
import HomeVue from '@/views/home/Home.vue';
import AboutVue from '@/views/about/AboutView.vue';
import ContactPage from '@/views/contact/ContactPage.vue';
import ForDevelopers from '@/views/whySmartworks/forDevelopers/ForDevelopers.vue';
import ForHiringCompanies from '@/views/whySmartworks/forHiringCompanies/ForHiringCompanies.vue';
import SmartworksCentral from '@/views/jobSeekers/smartworksCentral/SmartworksCentral.vue';
import CareVue from '@/views/care/CareVue.vue';
import CertifiedDeveloper from '@/views/jobSeekers/certifiedDevelopers/CertifiedDeveloper.vue';
import JobPlacementProgram from '@/views/jobSeekers/jobPlacementProgram/JobPlacement.vue';
// import Career from '@/views/career/Career.vue';
// renamed to Hire a Professional
// needs file name refactoring
import DeveloperStaffingAsAService from '@/views/service/developerStaffingAsAService/DSAAS.vue';
import SoftwareDevelopmentAsAService from '@/views/service/softwareDevelopmentAsAService/SDAAS.vue';
import JobOpenings from '@/views/jobOpenings/JobOpenings.vue';
import JobOpening from '@/views/jobOpenings/JobOpeningsShow.vue';
import JobApplication from '@/views/jobApplication/JobApplication.vue';
import ListEvents from '@/views/events/ListEvents.vue';
import ShowEvent from '@/views/events/ShowEvent.vue';
import ListTrainings from '@/views/trainings-and-certifications/ListTrainings.vue';
import ShowTrainings from '@/views/trainings-and-certifications/ShowTrainings.vue';
import PrivacyPolicy from '@/views/privacy-policy/PrivacyPolicy.vue';
import NotFound404 from '@/views/404/NotFound404.vue';
import CustomerBlog from '@/views/customerBlog/CustomerBlog.vue';
import CustomerVue from '@/views/customers/CustomersVue.vue';

// new service pages
import BuildAppService from '@/views/service/pages/BuildMyApp.vue';
import DesignAppService from '@/views/service/pages/DesignMyApp.vue';
import ReviewAppService from '@/views/service/pages/ReviewMyApp.vue';
import RoadmapAppService from '@/views/service/pages/RoadmapMyApp.vue';
import StaffAugumentation from '@/views/service/pages/StaffAugmentation.vue';
import AppIdentityManagement from '@/views/service/pages/IdentityManagementForMyApp.vue';







const routes = [
  {
    path: '/',
    name: 'home ',
    component: HomeVue,
    meta: {
      title: 'Build Apps & Scale Teams',
      description: 'Build your app and scale your team with DIGU. We deliver cost-effective solutions to enhance growth, productivity, and impact in Nepal.',
      keywords: 'DIGU, professional services, build apps, scale teams, hire developers, software solutions, startups, business growth, social impact',
      og: {
        title: 'Build Apps & Scale Your Team with DIGU | Scalable Software Solutions',
        description: 'Build your app and scale your team with DIGU. We deliver cost-effective solutions to enhance growth, productivity, and impact in Nepal.',
        url: 'https://www.digu.one',
        image: 'https://digu.one/img/Digu_Logo_Primary.a2e37f8d.webp',
      },
      twitter: {
        title: 'Build Apps & Scale Your Team with DIGU',
        description: 'Build your app and scale your team with DIGU. We deliver cost-effective solutions to enhance growth, productivity, and impact in Nepal.',
        image: 'https://digu.one/img/Digu_Logo_Primary.a2e37f8d.webp',
      },
    },
  
  },

 

 
  {
    path: '/build-my-app',
    name: 'buildmyapp',
    component: BuildAppService,
    meta: {
      title: 'Build My App with DIGU | Robust Software Development',
      description: 'Build scalable, high-quality apps with DIGU’s developers. From idea to launch, we deliver robust solutions to meet your goals.',
      keywords: 'build apps, app development, scalable apps, software solutions, DIGU, hire developers, robust applications',
      og: {
        title: 'Build My App with DIGU | Robust Software Development',
        description: 'Build scalable, high-quality apps with DIGU’s developers. From idea to launch, we deliver robust solutions to meet your goals.',
        url: 'https://www.digu.one/build-my-app',
      },
      twitter: {
        title: 'Build My App with DIGU | Robust Software Development',
        description: 'Build scalable, high-quality apps with DIGU’s developers. From idea to launch, we deliver robust solutions to meet your goals.',
      },
    },
  },
  {
    path: '/design-my-app',
    name: 'designmyapp',
    component: DesignAppService,
    meta: {
      title: 'Design My App with DIGU | Custom App Design Services',
      description: 'Create stunning, user-friendly app designs with DIGU’s expert design team. We craft custom designs tailored to your brand and user needs.',
      keywords: 'app design, custom design, user experience, DIGU, UI/UX design, mobile app design, web app design',
      og: {
        title: 'Design My App with DIGU | Custom App Design Services',
        description: 'Create stunning, user-friendly app designs with DIGU’s expert design team. We craft custom designs tailored to your brand and user needs.',
        url: 'https://www.digu.one/design-my-app',
      },
      twitter: {
        title: 'Design My App with DIGU | Custom App Design Services',
        description: 'Create stunning, user-friendly app designs with DIGU’s expert design team. We craft custom designs tailored to your brand and user needs.',
      },
    },
  },
  {
    path: '/review-my-app',
    name: 'reviewmyapp',
    component: ReviewAppService,
    meta: {
      title: 'Review My App with DIGU | Expert Code Assessments',
      description: 'Optimize your app with DIGU’s detailed code reviews and performance assessments to ensure quality and reliability.',
      keywords: 'code reviews, app optimization, software quality, DIGU, application assessment, performance review, user experience',
      og: {
        title: 'Review My App with DIGU | Expert Code Assessments',
        description: 'Optimize your app with DIGU’s detailed code reviews and performance assessments to ensure quality and reliability.',
        url: 'https://www.digu.one/review-my-app',
      },
      twitter: {
        title: 'Review My App with DIGU | Expert Code Assessments',
        description: 'Optimize your app with DIGU’s detailed code reviews and performance assessments to ensure quality and reliability.',
      },
    },
  },
  {
    path: '/roadmap-my-app',
    name: 'roadmapmyapp',
    component: RoadmapAppService,
    meta: {
      title: 'Roadmap My App with DIGU | Strategic App Planning',
      description: 'Plan your app’s success with DIGU’s expert roadmap services. Navigate development milestones confidently and effectively.',
      keywords: 'app roadmap, project planning, development milestones, DIGU, software strategy, app success, startup support',
      og: {
        title: 'Roadmap My App with DIGU | Strategic App Planning',
        description: 'Plan your app’s success with DIGU’s expert roadmap services. Navigate development milestones confidently and effectively.',
        url: 'https://www.digu.one/roadmap-my-app',
      },
      twitter: {
        title: 'Roadmap My App with DIGU | Strategic App Planning',
        description: 'Plan your app’s success with DIGU’s expert roadmap services. Navigate development milestones confidently and effectively.',
      },
    },
  },
  {
    path: '/staff-augmentation',
    name: 'StaffAugmentation',
    component: StaffAugumentation,
    meta: {
      title: 'Staff Augmentation with DIGU | Scale Your Team',
      description: 'Scale your business with DIGU’s staff augmentation services. Hire skilled engineers and maintain a seamless global workflow.',
      keywords: 'staff augmentation, hire engineers, remote talent, DIGU, engineering teams, global workflow, business scaling',
      og: {
        title: 'Staff Augmentation with DIGU | Scale Your Team',
        description: 'Scale your business with DIGU’s staff augmentation services. Hire skilled engineers and maintain a seamless global workflow.',
        url: 'https://www.digu.one/staff-augmentation',
      },
      twitter: {
        title: 'Staff Augmentation with DIGU | Scale Your Team',
        description: 'Scale your business with DIGU’s staff augmentation services. Hire skilled engineers and maintain a seamless global workflow.',
      },
    },
  },
  {
    path: '/implement-identity-management',
    name: 'implementIdentityManagement',
    component: AppIdentityManagement,
    meta: {
      title: 'Identity Management | Okta Services by DIGU & Anthropic',
      description: 'Transform your identity strategy with DIGU and Anthropic Identity. Access certified Okta professionals for scalable, secure solutions and seamless integration.',
      keywords: 'identity management, Okta consulting, identity solutions, DIGU, Anthropic Identity, customer identity, secure integration, scalable solutions, certified Okta professionals, IAM services',
      og: {
        title: 'Implement Identity Management | Okta Services by DIGU & Anthropic',
        description: 'Transform your identity strategy with DIGU and Anthropic Identity. Access certified Okta professionals for scalable, secure solutions and seamless integration.',
        url: 'https://www.digu.one/implement-identity-management',
      },
      twitter: {
        title: 'Implement Identity Management | Okta Services by DIGU & Anthropic',
        description: 'Transform your identity strategy with DIGU and Anthropic Identity. Access certified Okta professionals for scalable, secure solutions and seamless integration.',
      },
    },
  },
  
 


  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutVue,
    meta: {
      title: 'About DIGU | Mission-Driven Software Solutions Provider',
      description: "Learn about DIGU’s mission to empower professionals, deliver world class software solutions, and support businesses globally",
      keywords: 'about DIGU, mission-driven company, social impact, software solutions, startups, career growth, hire engineers, Nepal talent',
      og: {
        title: 'About DIGU | Mission-Driven Software Solutions Provider',
        description: " Learn about DIGU’s mission to empower professionals, deliver world-class software solutions, and support businesses globally.",
        url: 'https://www.digu.one/about',
        image: 'https://digu.one/img/Digu_Logo_Primary.a2e37f8d.webp',
      },
      twitter: {
        title: 'About DIGU | Mission-Driven Software Solutions Provider',
        description: " Learn about DIGU’s mission to empower professionals, deliver world-class software solutions, and support businesses globally.",
        image: 'https://digu.one/img/Digu_Logo_Primary.a2e37f8d.webp',
      },
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactPage,
    meta: {
      title: 'Contact Us',
    },
  },
  {
    path: '/for-job-seekers',
    redirect: '/why-digu-for-career-opportunities',
  },
  {
    path: '/why-smartworks-for-career-opportunities',
    redirect: '/why-digu-for-career-opportunities',
  },
  {
    path: '/why-digu-for-career-opportunities',
    name: 'WhyDIGUForCareerOpportunities',
    component: ForDevelopers,
    meta: {
      title: 'Why DIGU For Career Opportunities',
    },
  },
  {
    path: '/for-hiring-companies',
    redirect: '/why-smartworks-for-businesses',
  },
  {
    path: '/why-smartworks-for-businesses',
    redirect: '/why-digu-for-businesses',
  },
  {
    path: '/why-digu-for-businesses',
    name: 'whyDiguForBusinesses',
    component: ForHiringCompanies,
    meta: {
      title: 'Why DIGU | Scalable Solutions for Business Growth',
      description: "Partner with DIGU for scalable solutions, expert teams, and seamless collaboration to accelerate business growth and innovation.",
      keywords: 'about DIGU, mission-driven company, social impact, software solutions, startups, career growth, hire engineers, Nepal talent',
      og: {
        title: ' Why DIGU | Scalable Solutions for Business Growth',
        description: "Partner with DIGU for scalable solutions, expert teams, and seamless collaboration to accelerate business growth and innovation.",
        url: 'https://digu.one/why-digu-for-businesses',
        image: 'https://digu.one/img/Digu_Logo_Primary.a2e37f8d.webp',
      },
      twitter: {
        title: 'Why DIGU | Scalable Solutions for Business Growth',
        description: "Partner with DIGU for scalable solutions, expert teams, and seamless collaboration to accelerate business growth and innovation.",
        image: 'https://digu.one/img/Digu_Logo_Primary.a2e37f8d.webp',
      },
    },
  },
  {
    path: '/learn-about-smartworks-central',
    redirect: '/smartworks-central',
  },
  {
    path: '/learn-about-digu-central',
    redirect: '/smartworks-central',
  },
  {
    path: '/smartworks-central',
    redirect: '/digu-central',
  },
  {
    path: '/digu-central',
    name: 'SmartworksCentral',
    component: SmartworksCentral,
    meta: {
      title: 'Learn About DIGU Central Office',
    },
  },
  {
    path: '/care',
    redirect: '/smartworks-cares',
  },
  {
    path: '/smartworks-cares',
    redirect: '/digu-cares',
    
  },
  {
    path: '/digu-cares',
    name: 'SmartworksCares',
    component: CareVue,
    meta: {
      title: 'DIGU Cares | Impactful, Sustainable Programs in Nepal',
      description: "Explore DIGU Cares, supporting education, health, and economic growth in Nepal through impactful, sustainable programs.",
      keywords: 'DIGU Cares, social impact, education support, health programs, economic growth, Nepal development, sustainability',
      og: {
        title: 'DIGU Cares | Impactful, Sustainable Programs in Nepal',
        description: " Explore DIGU Cares, supporting education, health, and economic growth in Nepal through impactful, sustainable programs.",
        url: 'https://www.digu.one/digu-cares',
        image: 'https://digu.one/img/programs-section.1abee262.webp',
      },
      twitter: {
        title: 'DIGU Cares | Impactful, Sustainable Programs in Nepal',
        description: "Explore DIGU Cares, supporting education, health, and economic growth in Nepal through impactful, sustainable programs.",
        image: 'https://digu.one/img/programs-section.1abee262.webp',
      },
    },
  },

  {
    path: '/verified-badges',
    name: 'certifiedDeveloper',
    component: CertifiedDeveloper,
    meta: {
      title: 'Verified Badges with DIGU | Showcase Your Skills',
      description: "Showcase your skills with DIGU’s verified badges and gain credibility with global employers.",
      keywords: ' verified badges, skills certification, DIGU, global job readiness, professional credibility, certifications',
      og: {
        title: 'Verified Badges with DIGU | Showcase Your Skills',
        description: "Showcase your skills with DIGU’s verified badges and gain credibility with global employers.",
        url: 'https://www.digu.one/verified-badges',
        image: 'https://digu.one/img/Digu_Logo_Primary.a2e37f8d.webp',
      },
      twitter: {
        title: 'Verified Badges with DIGU | Showcase Your Skills',
        description: "Showcase your skills with DIGU’s verified badges and gain credibility with global employers.",
        image: 'https://digu.one/img/Digu_Logo_Primary.a2e37f8d.webp',
      },
    },
  },
  {
    path: '/job-placement-program',
    name: 'jobPlacement',
    component: JobPlacementProgram,
    meta: {
      title: 'Job Placement Program',
    },
  },
  // {
  //   path: '/career',
  //   redirect: '/join-our-talent-pool',
  // },
  // {
  //   path: '/join-our-talent-pool',
  //   name: 'joinOurTalentPool',
  //   component: Career,
  //   meta: {
  //     title: 'Join Our talent Pool',
  //   },
  // },
  {
    path: '/job-openings',
    redirect: '/talents-on-demand',
  },
  {
    path: '/talents-on-demand',
    name: 'developersOnDemand',
    component: JobOpenings,
    meta: {
      title: 'Developers On Demand',
    },
  },
  {
    path: '/job-openings/:id',
    redirect: '/talents-on-demand/:id',
  },
  {
    path: '/talents-on-demand/:id',
    name: 'developersOnDemandShow',
    component: JobOpening,
    meta: {
      title: 'Developers On Demand',
    },
  },
  {
    path: '/customers',
    name: 'customers',
    component: CustomerVue,
    meta: {
      title: 'Customer',
    },
  },
  {
    path: '/customers/:slug',
    name: 'customerBlog',
    component: CustomerBlog,
    meta: {
      title: 'Customer Blog',
    },
  },
  {
    path: '/job-application',
    name: 'jobApplication',
    component: JobApplication,
    meta: {
      title: 'Job Application',
    },
  },
  {
    path: '/developer-staffing-as-a-service',
    name: 'developerStaffingAsAService',
    redirect: '/hire-a-professional',
  },
  {
    path: '/hire-a-professional',
    name: 'hireAProfessional',
    component: DeveloperStaffingAsAService,
    meta: {
      title: 'Hire a Professional',
    },
  },
  {
    path: '/software-development-as-a-service',
    redirect: '/outsource-your-project',
  },
  {
    path: '/outsource-your-project',
    name: 'outsourceYoutProject',
    component: SoftwareDevelopmentAsAService,
    meta: {
      title: 'Outsource Your Project',
    },
  },
  {
    path: '/events',
    name: 'listEvents',
    component: ListEvents,
    meta: {
      title: 'Upcoming Events',
    },
  },
  {
    path: '/events/:id',
    name: 'showEvent',
    component: ShowEvent,
    meta: {
      title: 'DIGU Upcoming Events | Webinars, Workshops, and More',
      description: "Stay updated with DIGU’s upcoming events, featuring live webinars, expert-led workshops, and networking opportunities.",
      keywords: 'DIGU events, webinars, workshops, networking, professional growth, live sessions, training events',
      og: {
        title: 'DIGU Upcoming Events | Webinars, Workshops, and More',
        description: "Stay updated with DIGU’s upcoming events, featuring live webinars, expert-led workshops, and networking opportunities.",
        url: 'https://digu.one/events',
        image: 'https://digu.one/img/Digu_Logo_Primary.a2e37f8d.webp',
      },
      twitter: {
        title: 'DIGU Upcoming Events | Webinars, Workshops, and More',
        description: "Stay updated with DIGU’s upcoming events, featuring live webinars, expert-led workshops, and networking opportunities.",
        image: 'https://digu.one/img/Digu_Logo_Primary.a2e37f8d.webp',
      },
    },
  },
  {
    path: '/trainings-and-certifications',
    name: 'listTrainings',
    component: ListTrainings,
    meta: {
      title: 'DIGU Trainings & Certifications | Advance Your Career',
      description: "Advance your career with DIGU’s training and certification programs. Earn industry-recognized credentials to achieve professional growth.",
      keywords: 'DIGU training, certifications, professional growth, skill development, career advancement, industry credentials',
      og: {
        title: 'DIGU Trainings & Certifications | Advance Your Career',
        description: "Advance your career with DIGU’s training and certification programs. Earn industry-recognized credentials to achieve professional growth.",
        url: 'https://digu.one/trainings-and-certifications',
        image: 'https://digu.one/img/Digu_Logo_Primary.a2e37f8d.webp',
      },
      twitter: {
        title: 'DIGU Trainings & Certifications | Advance Your Career',
        description: "Advance your career with DIGU’s training and certification programs. Earn industry-recognized credentials to achieve professional growth.",
        image: 'https://digu.one/img/Digu_Logo_Primary.a2e37f8d.webp',
      },
    },
  },
  {
    path: '/trainings-and-certifications/:id',
    name: 'showTrainings',
    component: ShowTrainings,
    meta: {
      title: 'Show Training and Certification',
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: PrivacyPolicy,
    meta: {
      title: 'Privacy Policy',
    },
  },

  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound404,
    meta: {
      title: 'Not Found',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // Set document title
  document.title = to.meta.title ? `${to.meta.title} | DIGU` : 'DIGU';
  
  // Update meta tags dynamically
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute('content', to.meta.description || '');
  }

  const metaKeywords = document.querySelector('meta[name="keywords"]');
  if (metaKeywords) {
    metaKeywords.setAttribute('content', to.meta.keywords || '');
  }

  const ogTitle = document.querySelector('meta[property="og:title"]');
  if (ogTitle) {
    ogTitle.setAttribute('content', to.meta.og?.title || '');
  }

  const ogDescription = document.querySelector('meta[property="og:description"]');
  if (ogDescription) {
    ogDescription.setAttribute('content', to.meta.og?.description || '');
  }

  const ogUrl = document.querySelector('meta[property="og:url"]');
  if (ogUrl) {
    ogUrl.setAttribute('content', to.meta.og?.url || '');
  }

  const ogImage = document.querySelector('meta[property="og:image"]');
  if (ogImage) {
    ogImage.setAttribute('content', to.meta.og?.image || '');
  }

  const twitterTitle = document.querySelector('meta[name="twitter:title"]');
  if (twitterTitle) {
    twitterTitle.setAttribute('content', to.meta.twitter?.title || '');
  }

  const twitterDescription = document.querySelector('meta[name="twitter:description"]');
  if (twitterDescription) {
    twitterDescription.setAttribute('content', to.meta.twitter?.description || '');
  }

  const twitterImage = document.querySelector('meta[name="twitter:image"]');
  if (twitterImage) {
    twitterImage.setAttribute('content', to.meta.twitter?.image || '');
  }

  next();
});

export default router;
