<script setup>
import ClientCard from '@/components/partials/ClientCard.vue';
import { Carousel, Slide } from 'vue3-carousel';
import { ref, defineProps } from 'vue';

const props = defineProps({
  hasPB: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const clients = ref([
  {
    name: 'Ryan Schultz',
    title: 'Founder & CEO',
    company: 'DIVACS, Inc.',
    hasImage: true,
    logoName: 'divac-logo',
    message: "Working with DIGU has been an absolute pleasure. Their team of developers consistently impresses with their expertise, professionalism, and dedication to delivering top-notch solutions. Their innovative approach and attention to detail have greatly contributed to DIVACS' success. Collaborating with DIGU has truly elevated our expectations, and I look forward to many more projects with them in the future.",
  },
  {
    name: 'James Bonifield',
    title: 'Founder and Managing Partner',
    company: 'Anthropic Identity',
    hasImage: true,
    logoName: 'anthropic-logo',
    message: 'We are thrilled to partner with the team at DIGU. This collaboration accelerates our mission to deliver human-centric identity solutions—bridging product, development, and security. More importantly, it empowers a company that’s making a meaningful impact in so many lives.',
  },
  
  // Add more client testimonials here
]);

const carouselModel = ref(0);
</script>

<template>
  <div :class="{ 'pb-10': props.hasPB }" class="stories-container">
    <h3 class="dms700 section-title">
      What Do Our <span class="highlight">Customers</span> Say?
    </h3>
    
    <!-- Desktop View -->
    <div class="desktop-stories">
      <ClientCard
        v-for="(client, index) in clients"
        :key="index"
        v-bind="client" 
      />
    </div>
    
    <!-- Mobile View -->
    <div class="mobile-stories"
    >
      <Carousel
        v-model="carouselModel"
        :wrap-around="true"
        :items-to-show="1"
      >
        <Slide
          v-for="(client, index) in clients"
          :key="index"
          class="mobile-stories__slide"
        >
          <ClientCard v-bind="client" />
        </Slide>
        
        <!-- <template #addons>
          <Pagination class="carousel-pagination" />
        </template> -->
      </Carousel>

      <div class="slider-indicators">
        <div
          v-for="(_, index) in clients"
          :key="index"
          class="indicator"
          :class="{
            'active': index === carouselModel,
            'current': index === carouselModel
          }"
        >
          
        </div>
      </div>

    </div>

  </div>
</template>

<style scoped>
.stories-container {
  background-color: var(--light-1);
}

.section-title {
  background-color: var(--light-1);
  padding-block: 2rem;
  line-height: 1.75rem;
  font-size: 1.5rem;
}

.highlight {
  color: var(--accent-red);
}

.desktop-stories {
  
  display: none;
  width: 95%;
  max-width: 1400px;
  margin-inline: auto;
}

.mobile-stories {
  display: block;
}

.mobile-stories__slide {
  padding: 1rem;
}

.carousel-pagination {
  margin-top: 1rem;
}

@media (min-width: 1280px) {
  .desktop-stories {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .mobile-stories {
    display: none;
  }
}
.slider-indicators {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.5rem;
  padding-bottom: 1rem;
  background-color: var(--light-1);
}

.indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #E0E0E0;
  transition: all 0.3s ease;
}

.indicator.active {
  background: #2196F3;
}

.indicator.current {
  width: 24px;
  border-radius: 4px;
}

/* @media only screen and (min-width: 1280px) {
  .mobile-stories {
    display: none;
  }
} */
</style>