<template>
  <div class="impact-meter">
    <h2 class="impact-header">
      Your Trusted Partner in 
      <span class=" h-title underline">&nbsp;Success</span>
    </h2>

    <div class="impact-container" ref="impactContainer">
      <div class="sidebar-wrapper">
        <div class="sticky-sidebar" ref="stickySidebar">
          <div class="slider-section">
            <h2 class="title">
              Impact&nbsp;<br /><span class="highlight">Meter</span>
            </h2>
            <p class="subtitle">See how your onboarding creates change</p>

            <div class="input-container">
              <div class="label-container">
                <v-icon class="user-icon" icon="mdi-account-outline" />
                <span class="label-text">Onboarded By You</span>
              </div>

              <div class="slider-container">
                <input
                  type="range"
                  v-model="selectedProfessionals"
                  min="1"
                  max="20"
                  class="slider"
                  @input="handleSliderChange"
                  :style="{
                    '--value': selectedProfessionals,
                    '--value-text': `'${selectedProfessionals}'`,
                  }"
                />
                <div
                  class="thumb-value"
                  :style="{
                    left: `calc(${valuePosition}% + (${
                      valuePosition / 100
                    } * -40px))`,
                  }"
                >
                  {{ selectedProfessionals }}
                </div>
                <div class="range-labels">
                  <span>1</span>
                  <span>20</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="impact-content" ref="impactContent">
        <CategorySection
          v-for="(category, index) in Object.keys(metrics)"
          :key="index"
          :title="getCategoryTitle(category)"
          :metrics="metrics[category]"
          :highlightHeader="category === 'overallImpact'"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  nextTick,
  watch,
  computed,
} from 'vue';
import CategorySection from './CategorySection.vue';

const stickySidebar = ref(null);
const impactContent = ref(null);
const impactContainer = ref(null);
const selectedProfessionals = ref(5);
const valuePosition = computed(
  () => ((selectedProfessionals.value - 1) / 19) * 100
);

const metrics = ref({
  jobCreation: [
    { label: 'Onboarded By You', value: 0 },
    { label: 'Onboarded For Digu', value: 0 },
    { label: 'Interns/ Entry Level Hired', value: 0 },
  ],
  professionalDevelopment: [
    { label: 'Communication Workshops Sponsored', value: 0 },
    { label: 'Mentorship Program Sponsored', value: 0 },
    { label: 'Resume-building Workshops Sponsored', value: 0 },
    { label: 'Project Management Training Workshops Sponsored', value: 0 },
    { label: 'Professional Certifications Sponsored', value: 0 },
    { label: 'Networking Events Sponsored', value: 0 },
    { label: 'Third Party Events Sponsored', value: 0 },
  ],
  scholarships: [{ label: 'Scholarships Awarded', value: 0 }],
  communityGrowth: [
    { label: 'Livelihood-Driven Business Supported', value: 0 },
    { label: 'NGO-Partnered Programs Supported', value: 0 },
    { label: 'Educational Institutions Supported', value: 0 },
  ],
  overallImpact: [
    { label: 'Families Supported', value: 0 },
    { label: 'Digital Projects Supported', value: 0 },
    { label: 'Skilled Professionals Retained in Nepal', value: 0 },
    { label: 'Youth Retained for Higher Education', value: 0 },
  ],
});

const handleStickySidebar = () => {
  const sidebar = stickySidebar.value;
  const content = impactContent.value;
  const container = impactContainer.value;

  if (!sidebar || !content || !container) return;

  const navbarHeight = 80;

  const updateStickyState = () => {
    const containerRect = container.getBoundingClientRect();
    const sidebarHeight = sidebar.offsetHeight;
    const contentHeight = content.offsetHeight;
    const viewportHeight = window.innerHeight;

    sidebar.style.position = 'static';
    sidebar.style.width = '100%';

    if (containerRect.height > viewportHeight) {
      if (containerRect.top <= navbarHeight) {
        sidebar.style.position = 'fixed';
        sidebar.style.top = `${navbarHeight}px`;
      }

      if (containerRect.bottom < sidebarHeight + navbarHeight) {
        sidebar.style.position = 'absolute';
        sidebar.style.top = `${contentHeight - sidebarHeight}px`;
      }
    }
  };

  window.addEventListener('scroll', updateStickyState);
  window.addEventListener('resize', updateStickyState);

  nextTick(updateStickyState);

  return () => {
    window.removeEventListener('scroll', updateStickyState);
    window.removeEventListener('resize', updateStickyState);
  };
};

const updateMetrics = () => {
  const hiredProfessionals = selectedProfessionals.value;

  // Job Creation Metrics
  metrics.value.jobCreation[0].value = hiredProfessionals; // Onboarded By You
  metrics.value.jobCreation[1].value = Math.floor(hiredProfessionals / 3); // Onboarded For Digu
  metrics.value.jobCreation[2].value = (() => {
    if (hiredProfessionals < 3) return 0;
    if (hiredProfessionals <= 5) return 1;
    if (hiredProfessionals <= 8) return 2;
    let baseInterns = 2;
    let additionalInterns = 0;
    for (let i = 9; i <= hiredProfessionals; i += 3) {
      additionalInterns += 2;
    }
    return baseInterns + additionalInterns;
  })(); // Interns/Entry Level

  // Professional Development Metrics [unchanged]
  metrics.value.professionalDevelopment[0].value = (() => {
    if (hiredProfessionals < 3) return 0;
    if (hiredProfessionals === 3) return 1;
    if (hiredProfessionals === 4) return 2;
    const additionalWorkshops = Math.floor((hiredProfessionals - 4) / 3);
    return 2 + additionalWorkshops;
  })();
  metrics.value.professionalDevelopment[1].value = (() => {
    if (hiredProfessionals < 3) return 0;
    if (hiredProfessionals <= 5) return 1;
    if (hiredProfessionals <= 8) return 2;
    let baseMentor = 2;
    let additionalMentor = 0;
    for (let i = 9; i <= hiredProfessionals; i += 3) {
      additionalMentor += 2;
    }
    return baseMentor + additionalMentor;
  })();
  metrics.value.professionalDevelopment[2].value = Math.ceil(
    hiredProfessionals / 2
  );
  metrics.value.professionalDevelopment[3].value =
    hiredProfessionals < 10
      ? Math.floor(hiredProfessionals / 10)
      : Math.floor(hiredProfessionals / 10) +
        Math.floor((hiredProfessionals - 10) / 5);
  metrics.value.professionalDevelopment[4].value =
    hiredProfessionals < 10
      ? Math.floor(hiredProfessionals / 10) * 4
      : 4 + (hiredProfessionals - 10);
  metrics.value.professionalDevelopment[5].value = Math.floor(
    hiredProfessionals / 10
  );
  metrics.value.professionalDevelopment[6].value = Math.floor(
    hiredProfessionals / 15
  );

  // Scholarships Metrics
  metrics.value.scholarships[0].value = Math.floor(hiredProfessionals / 2);

  // Community Growth Metrics
  metrics.value.communityGrowth[0].value = Math.floor(hiredProfessionals / 5); // Livelihood-Driven
  metrics.value.communityGrowth[1].value =
    hiredProfessionals < 15 ? 0 : 1 + Math.floor((hiredProfessionals - 15) / 5);
  metrics.value.communityGrowth[2].value = Math.floor(hiredProfessionals / 5);

  // Overall Impact Metrics - Corrected Calculations

  metrics.value.overallImpact[0].value =
    Number(metrics.value.jobCreation[0].value) + // Onboarded By You
    Number(metrics.value.jobCreation[1].value) + // Onboarded For Digu
    Number(metrics.value.jobCreation[2].value) + // Interns/Entry Level
    Number(metrics.value.scholarships[0].value) + // Scholarships
    Number(metrics.value.communityGrowth[0].value); // Livelihood-Driven

  // Digital Projects = floor(hiredProfessionals / 10)
  metrics.value.overallImpact[1].value = Math.floor(hiredProfessionals / 10);

  // Skilled Professionals = Onboarded By You + Onboarded For Digu + Interns
  metrics.value.overallImpact[2].value =
    Number(metrics.value.jobCreation[0].value) + // Onboarded By You
    Number(metrics.value.jobCreation[1].value) + // Onboarded For Digu
    Number(metrics.value.jobCreation[2].value); // Interns/Entry Level

  // Youth Retained = Scholarships
  metrics.value.overallImpact[3].value = metrics.value.scholarships[0].value;
};

const getCategoryTitle = (category) => {
  const titles = {
    jobCreation: 'Job Creation',
    professionalDevelopment: 'Professional Development Programs',
    scholarships: 'Scholarships and Education',
    communityGrowth: 'Community Growth',
    overallImpact: 'Overall Grassroot Impact',
  };
  return titles[category] || category;
};

watch(selectedProfessionals, () => {
  updateMetrics();
});

onMounted(() => {
  updateMetrics();
  const cleanupStickySidebar = handleStickySidebar();

  onBeforeUnmount(() => {
    if (cleanupStickySidebar) {
      cleanupStickySidebar();
    }
  });
});
</script>

<style scoped>
.impact-meter {
  font-family: Arial, sans-serif;
  max-width: 1400px;
  margin: 1rem auto;
  padding: 4rem 2rem 4rem;
}

.impact-header {
  font-size: 64px;
  font-weight: bold;
  text-align: center;
  color: #333;
  /* margin: 5rem 3rem 1rem 2rem; */
  display: flex;
  padding-top: 5rem;
  padding-bottom: 0;
  flex-wrap: wrap;
  justify-content: center;
}
.h-title {
  margin-right: 2rem;
}
.impact-container {
  display: flex;
  gap: 2rem;
  position: relative;
}

.sidebar-wrapper {
  flex: 0 0 350px;
  position: relative;
  margin-right: 2rem;
}

.sticky-sidebar {
  position: sticky;
  top: 60px;
  padding-top: 4rem;
  width: 250px;
}

.slider-section {
  padding-top: 2rem;
  padding-left: 3rem;
  width: 250px;
  text-align: left;
}

.title {
  font-size: 35px;
  font-weight: bold;
  line-height: 1.1;

  padding: 0;
  text-align: left;
  align-items: left;
}

.highlight {
  color: #ff6860;
}

.subtitle {
  font-size: 16px;
  color: #333;
  margin: 1rem 0 2rem;

  text-align: left;
}

.input-container {
  margin-top: 2rem;
}

.label-container {
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.user-icon {
  color: #ff6860;
  font-size: 24px;
  margin-bottom: 5px;
}

.label-text {
  font-size: 16px;
  color: #333;
}

.slider-container {
  position: absolute;
}

.slider-wrapper {
  position: relative;
}

.thumb-value {
  position: absolute;
  top: -11px;
  width: 41px;
  height: 40px;
  background: #fff;
  border: 2px solid #00aeff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00aeff;
  font-size: 14px;
  font-weight: bold;
  pointer-events: none;
  z-index: 3;
}

.slider {
  width: 180px;
  height: 10px;
  -webkit-appearance: none;
  background: #e8e8e8;
  outline: none;
  border-radius: 22px;
  position: relative;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  z-index: 2;
  margin-top: -18px;
}

.slider::-moz-range-thumb {
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  z-index: 2;
  margin-top: -18px;
}

.slider::-webkit-slider-runnable-track {
  background: linear-gradient(
    to right,
    #00aeff 0%,
    #00aeff calc((var(--value, 0) - 1) / 19 * 100%),
    #e8e8e8 calc((var(--value, 0) - 1) / 19 * 100%),
    #e8e8e8 100%
  );
  height: 10px;
  border-radius: 2px;
}

.range-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  color: #666;
  font-size: 14px;
}

.impact-content {
  flex: 1;
  max-width: 800px;
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-right: 5%;
}
:deep(.overall-impact) {
  border-radius: 12px;
  background: #e4e0e0;

  border: 2px solid #ff6860;
}
/* Responsive Styles */

@media screen and (max-width: 1200px) {
  .impact-header {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 1198px) {
  .impact-container {
    gap: 2rem;
  }
}

@media screen and (max-width: 850px) {
  .impact-meter {
    padding: 0;
    padding-top: 3rem;
    margin: 1rem 0;
  }

  .impact-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 4rem;

    margin: 0;
  }

  .sidebar-wrapper {
    flex: 0 0 220px; /* Maintain fixed width for sidebar */
    margin: 0 auto; /* Center-align if needed */
    /* width: 200px; */
    position: sticky;
    top: 0;
    z-index: 10;
    margin-top: 0;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }

  .sticky-sidebar {
    position: sticky; /* Ensure it remains sticky */
    top: 80px; /* Adjust if navbar height differs */
    padding: 1rem;

    height: 320px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .slider-section {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 200px;
    text-align: center;
  }

  .impact-content {
    width: 100%;
    max-width: 100vw;
    margin: 0;

    padding: 0rem 2rem 0; /* Add top padding instead */
    position: relative; /* Add relative positioning */
    z-index: 1; /* Lower z-index than sidebar */
    background-color: white;
  }
  .title {
    font-size: 24px;
    font-weight: bold;
    display: flex;
    margin: 0;
    /* line-height: 1.1; */
    margin-left: 1rem;
    padding: 0;
    text-align: center;
  }
  .subtitle {
    text-align: center;
    margin: 0rem;
    margin-top: 1rem;
  }
  .impact-header {
    font-size: 30px;
    margin: 0;
    text-align: center;
    padding-left: 1.1rem;
    padding-right: 0.8rem;
    /* margin-top: 3rem; */
  }
}
@media screen and (max-width: 469px) {
  .h-title {
    padding-left: 2rem;
    /* margin-right: rem; */
  }
}
@media screen and (max-width: 407px) {
  .h-title {
    /* margin-right: 3rem; */
  }
  
}
@media screen and (max-width: 297px) {
  .impact-header {
    margin-right: 3rem;
  }
}

@media screen and (max-width: 380px) {
  .impact-header {
    padding: 0;
    padding-top: 3rem;
    text-align: center;
  }
}

.underline {
  position: relative;
  display: inline-block;
}

.underline::after {
   content: "";
  position: absolute;
  left: 50%;
  bottom: -5px;
  width: 80%;
  height: 10px;
  background-image: url("@/assets/img/underline.svg"); /* Using alias */
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateX(-50%);
}
</style>
