<template>
    <section class="why-section">
      <div class="section__container">
        <v-row class="why__row">
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            class="section__col why__img"
          >
            <keep-alive>
              <v-img
                class="why__image"
                cover
                :eager="true"
                :lazy-src="require('@/assets/img/lazy-img.jpg')"
                :src="require('@/assets/img/whymatter-surya.jpg')"
              > 
                <template v-slot:placeholder>
                  <div class="d-flex align-center justify-center fill-height">
                    <v-progress-circular
                      color="grey-lighten-4"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </template>
              </v-img>
            </keep-alive>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            class="why__text section__col"
          >
            <div class="content__wrapper">
              <p class="o600 why__subtitle section__subtitle-2">Why It <span class="underline">Matters</span></p>
              <keep-alive>
                <v-img
                  class="mobile-why__image"
                  cover
                  :eager="true"
                  :lazy-src="require('@/assets/img/lazy-img.jpg')"
                  :src="require('@/assets/img/whymatter-surya.jpg')"
                >
                  <template v-slot:placeholder>
                    <div class="d-flex align-center justify-center fill-height">
                      <v-progress-circular
                        color="grey-lighten-4"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                  </template>
                </v-img>
              </keep-alive>
              <p class="m500 why__description section__description-2">
                <!-- Our roots originate in Nepal - a beautiful country with limited
                career opportunities. This lack of opportunity drives Nepali
                professionals to look for tech jobs outside of Nepal. -->
  
                By keeping talented professionals in Nepal, we’re not just creating jobs—we’re empowering people to build a better future for themselves, their families, and their country. And by helping startups succeed, we’re fueling innovation on a global scale.
              </p>
              <p class="m500 why__description section__description-2">
                At DIGU, we’re more than a company. We’re a bridge between dreams and reality, solving real problems for people who deserve better.
              </p>

              <p class="m500 why__description section__description-2">
                Want to join us in creating impact?
              </p>
              
  
              <div class="why__btn-container">
                <v-btn
                  @click="redirectToCalendly"
                  class="why__button o500"
                  elevation="0"
                  size="x-large"
                >
                  Let's Connect
                  <v-icon class="why__button-icon">mdi-arrow-right</v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
  
     
    </section>
  </template>
  
  <script>
  import { register } from 'swiper/element/bundle';
  register();
  
  export default {
    name: 'WhyItMatter',
   
    methods: {
        redirectToCalendly() {
        window.open(
            "https://calendly.com/digu/meeting-with-sunbid-nepali-time",
            "_blank"
        );
        },
    },
    
  };
  </script>
  
  <style scoped>
  .why-section {
    display: block;
      padding-block: 4rem 5rem;
  
    /* background: #CFECFC; */
  }
  
  @media only screen and (min-width: 1200px) {
    .why-section {
      padding-block: 8rem 6rem;
    }
  }
  
  .why__row {
    padding-inline: 0px;
  }
  
  .why__text {
    display: flex;
    flex-direction: column;
    justify-content: left;
  }
  
  @media only screen and (min-width: 1280px) {
    .why__text {
      padding-inline: 2rem 0px;
    }
  }
  
  .why__subtitle,
  .why__title {
    text-align: left;
  }
  
  @media only screen and (min-width: 1200px) {
    .why__subtitle,
    .why__title {
      text-align: left;
    }
  }
  
  .why__subtitle {
    color: #17171F;
    margin-bottom: 0.75rem;
    /* font-size: 56px !important; */
    font-size: clamp(32px, 5vw, 56px) !important;
  }
  
  .why__title {
    color: var(--dark-3);
    line-height: 110%;
    margin-bottom: 0.5rem;
  }
  
  .why__description {
    text-align: left;
    font-size: 1;
    color: var(--dark-2);
    margin-bottom: 1rem;
    width: 100%;
  }
  
  @media only screen and (min-width: 1200px) {
    .why__description {
      text-align: left;
    }
  }
  
  .why__btn-container {
    text-align: left;
    margin-top: 3rem;
  }
  
  .why__button {
    background-color: #007AB2;
    color: white;
    text-transform: capitalize;
    letter-spacing: 0px;
    font-size: 1rem;
    text-align: left;
    border-radius:10px;
    position: relative;
    padding-right: 50px !important; /* Space for the arrow */
    transition: all 0.3s ease;
  }
  
  .why__button {
    background-color: #007AB2;
    color: white;
    text-transform: capitalize;
    letter-spacing: 0px;
    font-size: 1rem;
    border-radius: 10px;
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding-right: 24px !important;
  }
  
  .why__button-icon {
    opacity: 0;
    transform: translateX(-10px);
    transition: all 0.3s ease;
    font-size: 1.25rem;
    margin-left: 4px;
  }
  
  .why__button:hover .why__button-icon {
    opacity: 1;
    transform: translateX(0);
  }
  
  .why__img {
    display: none;
  }
  
  .underline {
    position: relative;
    display: inline-block;
  }
  
  .underline::after {
     content: "";
    position: absolute;
    left: 50%;
    bottom: -5px;
    width: 100%;
    height: 10px;
    background-image: url("@/assets/img/underline.svg"); /* Using alias */
    background-repeat: no-repeat;
    background-size: contain;
    transform: translateX(-50%);
  }
  
  @media only screen and (min-width: 960px) {
   
    .why__img {
      display: block;
    }
  }
  
  @media only screen and (max-width: 960px) {
    .why-section {
      display: flex;
      flex-direction: column;
      align-items: left;
      text-align: left;
    }
    .why__subtitle,
    .why__title {
      text-align: left;
      align-items: left;
      padding: 0 1rem;
    }
  
    .why__row {
      display: flex;
      flex-direction: column;
      /* padding: 0 2rem; */
      align-items: left;
      justify-content: left;
    }
  
    .why__text {
      display: flex;
      flex-direction: column;
      /* padding: 0 1rem; */
      align-items: left;
      text-align: left;
    }
  
    .why__description {
      /* padding: 0 9rem; */
      padding: 0 1rem;
      text-align: left;
    }
  
    .why__btn-container {
      display: flex;
      padding: 0 1rem;
      justify-content: left;
      width: 100%;
    }
  }
  
  
  .why__image {
    /* margin-left: 6rem; */
    max-width: 500px;
    border-radius: 0.8rem;
    margin-right: auto;
    padding-right: 0px;
    aspect-ratio: 5/5;
  }
  
  .mobile-why__image {
    margin-block: 2rem;
    margin-inline: auto;
    border-radius: 0.8rem;
    aspect-ratio: 5/2/3;
    max-width: 500px;
    margin-inline: auto;
  }
  
  @media only screen and (min-width: 960px) {
    .why-section{
      text-align: center;
      align-items: center;
      justify-content: center;
    }
    .mobile-why__image {
      display: none;
    }
  }
  
  
  
  </style>